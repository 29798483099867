import { useCallback, useState } from "react";
import { createPortal } from "react-dom";
import { toast } from "react-toastify";
import sound from "assets/notification.mp3";
import { usePreferences } from "hooks/usePreferences";
import { Howl } from "howler";

export const useToast = () => {
	const { preferences } = usePreferences();
	const [numToasts, setNumToasts] = useState(0);
	let hasToasts = document.querySelectorAll(".Toastify__toast").length;

	const resetOnceOnlyToast = useCallback((id) => {
		let toasts = JSON.parse(window.sessionStorage.getItem("toasts"));
		if (toasts && !id) {
			window.sessionStorage.removeItem("toasts");
			return;
		}
		if (toasts) {
			let newToasts = toasts.filter((toast) => toast !== id);
			window.sessionStorage.setItem("toasts", JSON.stringify(newToasts));
		}
	}, []);

	const showToast = useCallback(
		(options) => {
			let style = options.style ?? "info";
			let currToasts = JSON.parse(window.sessionStorage.getItem("toasts")) ?? [];

			if (!preferences.notifications) {
				return;
			}
			if (options.onceOnly && currToasts.includes(options.id)) {
				return;
			}
			toast(options.message, {
				type: style,
				toastId: options.id, //toastId prevent duplicate toasts
				autoClose: options.autoClose ?? false,
				onOpen: () => {
					setNumToasts((numToasts) => numToasts + 1);
					if (!preferences.mute && (style === "warning" || style === "error")) {
						//define sound Howl inside conditional. if defined outside, it will play every time showToast is called
						var warningSound = new Howl({
							src: [sound],
							autoplay: true,
						});
						warningSound.play();
					}
				},
				onClose: () => {
					setNumToasts((numToasts) => numToasts - 1);
					if (options.onceOnly) {
						let currToasts = JSON.parse(window.sessionStorage.getItem("toasts")) ?? [];
						window.sessionStorage.setItem("toasts", JSON.stringify([...currToasts, options.id]));
					}
				},
			});
		},
		[preferences],
	);

	const removeToast = useCallback((id) => {
		return id ? toast.dismiss(id) : toast.dismiss();
	}, []);

	const clearAllToasts = useCallback(() => {
		toast.dismiss();
	}, []);

	const updateToastById = useCallback((toastId, content, options) => {
		toast.update(toastId, {
			render: content,
			...options,
		});
	}, []);

	//as of this writing, react-toastify does not have a built-in clear all button for toastcontainers. this is a workaround
	// <ClearAllToastBtn /> must be rendered in every component that uses showToast and will be added to DOM for every one
	//for some reason need both hasToasts and numToasts to work properly
	const ClearAllToastBtn = useCallback(() => {
		if (hasToasts && numToasts) {
			return createPortal(
				<button
					className='reset'
					id='clear-all-toast-btn'
					style={{
						cursor: "pointer",
						position: "fixed",
						bottom: "0",
						right: "0",
						margin: "0.5rem 1rem",
						zIndex: "10000",
					}}
					onClick={() => clearAllToasts()}
				>
					Clear All
				</button>,
				document.getElementById("App"),
			);
		} else {
			return null;
		}
	}, [clearAllToasts, hasToasts, numToasts]);

	return { showToast, removeToast, clearAllToasts, updateToastById, ClearAllToastBtn, resetOnceOnlyToast };
};

// USAGE

//  let {showToast,ClearAllToastBtn} = useToast();
