import { useState } from "react";
import {
	faUser,
	faChartLine,
	faCamera,
	faHome,
	faBars,
	faBarsStaggered,
	faGears,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CAMERAVIEW, FLIGHTLOGS, DASHBOARD, LOGOUT, PROFILE, SETTINGS } from "constants/constants";
import { useClickAwayListener } from "hooks/useClickAwayListener";
import { useThrottle } from "hooks/useThrottle";
import DarkModeSwitch from "./DarkModeSwitch";
import "./SideBar.css";
import SidebarPopup from "./SidebarPopup";
import "Responsive.css";

export default function SideBar({ page, handleRouteChange, handlePageChange }) {
	const [isDisplayed, setIsDisplayed] = useState(false);

	const [activePage, setActivePage] = useState(page);
	const [popupOpen, setPopupOpen] = useState("");

	const toggleNav = () => {
		setIsDisplayed(!isDisplayed);
		setPopupOpen("");
	};

	let clickAwayRef = useClickAwayListener(toggleNav);

	const changePage = (page) => {
		handlePageChange(page);
		setActivePage(page);
	};

	const throttledChangePage = useThrottle(changePage, 1000);

	const NavElement = ({
		title,
		page,
		icon,
		style = null,
		onMouseEnter = null,
		onMouseLeave = null,

		children,
	}) => {
		return (
			<div
				className='fr clickable'
				style={{
					...style,
					color: activePage === page ? "var(--icon-color)" : "var(--secondary)",
				}}
				id={page}
				onClick={() => throttledChangePage(page)}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
			>
				<h5>{title}</h5>
				<FontAwesomeIcon icon={icon} className='icon' />
				{children}
			</div>
		);
	};

	return (
		<nav
			className={`${isDisplayed ? "side-bar nav-display" : "side-bar nav-hide"}`}
			ref={isDisplayed ? clickAwayRef : null}
		>
			<div id='nav-toggle-container' title='Navigation Menu'>
				<button id='nav-toggle' className='clickable' onClick={() => toggleNav()}>
					<FontAwesomeIcon icon={isDisplayed ? faBars : faBarsStaggered} className='icon' />
				</button>
			</div>
			<section>
				<NavElement title='Dashboard' page={DASHBOARD} icon={faHome} />
				<NavElement title='Flight Logs' page={FLIGHTLOGS} icon={faChartLine} />
				<NavElement title='Video Feed' page={CAMERAVIEW} icon={faCamera} />
				{/* <NavElement title='Snapshots' page={DETECTION} icon={faImages} /> */}
			</section>

			<section className='bottom'>
				<NavElement
					title='User'
					page={PROFILE}
					icon={faUser}
					style={{ position: "relative" }}
					onMouseEnter={() => setPopupOpen(PROFILE)}
					onMouseLeave={() => setPopupOpen("")}
				>
					<SidebarPopup
						popupOpen={popupOpen === PROFILE}
						popupListItems={[
							{ name: "Profile", onClick: () => handlePageChange(PROFILE) },
							{ name: "Sign Out", onClick: () => handleRouteChange(LOGOUT) },
						]}
					/>
				</NavElement>
				<NavElement title='Settings' page={SETTINGS} icon={faGears} />

				<div className='clickable' id='dark-mode-switch-container' title='Dark Mode'>
					<DarkModeSwitch />
				</div>
			</section>
		</nav>
	);
}
