import { useTheme } from "hooks/useTheme";
import "./DarkModeSwitch.css";

export default function DarkModeSwitch() {
	const { isDark, toggleDarkTheme } = useTheme();
	return (
		<div className='icon switch'>
			<input
				id='dark-mode-switch'
				type='checkbox'
				checked={isDark}
				onChange={(e) => toggleDarkTheme(e.target.checked)}
			/>
			<label className='dark-mode-switch-label' htmlFor='dark-mode-switch'>
				<span className='dark-mode-switch-button fc'>
					{isDark ? (
						<svg xmlns='http://www.w3.org/2000/svg' height='20' width='20' viewBox='0 0 20 20'>
							<path
								fill='#fff'
								d='M 8.7 5.7 a 5.6 5.6 90 0 0 9.6 3.9 v 0.1 c 0 4.4 -3.6 8 -8 8 S 2.3 14.1 2.3 9.7 S 5.9 1.7 10.3 1.7 h 0.1 A 5.6 5.6 90 0 0 8.7 5.7 z z	'
							/>
						</svg>
					) : (
						<svg xmlns='http://www.w3.org/2000/svg' height='20' width='20' viewBox='0 0 20 20'>
							<path
								fill='#fff'
								d='M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z'
							/>
						</svg>
					)}
				</span>
			</label>
		</div>
	);
}
