import { useEffect, useRef, useState } from "react";
import { useClickAwayListener } from "hooks/useClickAwayListener";
import PropTypes from "prop-types";
import "./Autocomplete.css";

export default function Autocomplete({ options, userInput, handleInputChange, placeholder }) {
	const [activeOption, setActiveOption] = useState(0);
	const [filteredOptions, setFilteredOptions] = useState([]);
	const [showOptions, setShowOptions] = useState(false);

	let clickAwayRef = useClickAwayListener(() => setShowOptions(false));

	const scrollRef = useRef(null);

	useEffect(() => {
		showOptions &&
			filteredOptions.length > 0 &&
			scrollRef.current &&
			scrollRef.current.scrollIntoView({ block: "end", behavior: "smooth" });
	}, [showOptions, filteredOptions]);

	const handleChange = (e) => {
		const userInput = e.currentTarget.value;
		const filteredOptions = options.filter(
			(optionName) => optionName.toLowerCase().indexOf(userInput.toLowerCase()) > -1
		);

		setActiveOption(0);
		setFilteredOptions(filteredOptions);
		handleInputChange(e.currentTarget.value);
		if (userInput === filteredOptions[0]) {
			return;
		}
		setShowOptions(true);
	};

	const handleSelect = (e) => {
		setActiveOption(0);
		setFilteredOptions([]);
		setShowOptions(false);
		handleInputChange(e.currentTarget.innerText);
	};

	const handleKeyDown = (e) => {
		if (e.key === "Enter") {
			setActiveOption(0);
			setShowOptions(false);
			handleInputChange(filteredOptions[activeOption]);
		} else if (e.key === "ArrowUp") {
			if (activeOption === 0) {
				return;
			}
			setActiveOption(activeOption - 1);
		} else if (e.key === "ArrowDown") {
			if (activeOption === filteredOptions.length - 1) {
				return;
			}
			setActiveOption(activeOption + 1);
		} else if (e.key === "Tab") {
			if (e.target.className === "search-box" || activeOption === filteredOptions.length - 1) {
				return;
			}
			setActiveOption(activeOption + 1);
		}
	};

	let optionList;
	if (showOptions && userInput) {
		if (filteredOptions.length) {
			optionList = (
				<ul className='options' ref={scrollRef}>
					{filteredOptions.map((optionName, index) => {
						let className;
						if (index === activeOption) {
							className = "option-active";
						}
						return (
							<li
								className={className}
								key={optionName}
								onClick={handleSelect}
								onMouseOver={() => setActiveOption(index)}
								tabIndex='0'
							>
								{optionName}
							</li>
						);
					})}
				</ul>
			);
		} else {
			optionList = (
				<div className='no-options'>
					<em>No Option!</em>
				</div>
			);
		}
	}

	return (
		<div className='autocomplete-search-container' ref={clickAwayRef} onKeyDown={handleKeyDown}>
			<input
				type='text'
				className='search-box'
				onChange={handleChange}
				onFocus={handleChange}
				value={userInput}
				placeholder={placeholder}
			/>
			{optionList}
		</div>
	);
}

Autocomplete.propTypes = {
	options: PropTypes.instanceOf(Array).isRequired,
};
