import { useEffect, useRef, useState } from "react";
import { faTrashCan, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Autocomplete from "components/_GENERAL/Autocomplete";
import { cleanFloatInput } from "utils/helpers";
import "./DroneList.css";

export default function DroneList({
	unitDrones,
	handleInputByIndex,
	dronePresetModels,
	handleAddDrone,
	handleRemoveDrone,
}) {
	const [numFieldsets, setNumFieldsets] = useState([]);
	const scrollRef = useRef(null);

	useEffect(() => {
		let sortedIndexes = unitDrones.map((drone) => drone.id).sort();
		setNumFieldsets(sortedIndexes);
	}, [unitDrones]);

	const handleAddFieldset = (e) => {
		e.preventDefault();
		//increase numFieldsets by 1
		let newIndex = numFieldsets[numFieldsets.length - 1] + 1;
		let newFieldsets = [...numFieldsets, newIndex];
		setNumFieldsets(newFieldsets);

		//add new drone to unitDrones
		let newDrone = { id: newIndex, model: "", weight: 0 };
		handleAddDrone(newDrone);

		//scroll down to new li item
		scrollRef.current.scroll({ top: scrollRef.current.scrollHeight, behavior: "smooth" });
	};

	const handleDelete = (index) => {
		if (numFieldsets.length === 1) {
			return;
		}
		//remove index from numFieldsets
		let newIndexes = numFieldsets.filter((num) => num !== index);
		setNumFieldsets(newIndexes);
		//remove drone from unitDrones
		handleRemoveDrone(index);
	};

	const handleScroll = (e) => {
		let parentBottom = scrollRef.current.getBoundingClientRect().bottom;
		let targetBottom = e.target.getBoundingClientRect().bottom;
		if (targetBottom > parentBottom) {
			e.target.scrollIntoView({ behavior: "smooth" });
		}
	};

	return (
		<div className='drone-list' data-testid='drone-reg-drone-list'>
			{unitDrones.length === numFieldsets.length && (
				<ul ref={scrollRef}>
					{numFieldsets.map((index) => (
						<li key={index} className='fr drone-list-item'>
							<div className='fr drone-fieldset-wrapper' onClick={handleScroll}>
								<Autocomplete
									userInput={unitDrones.find((drone) => drone.id === index).model}
									handleInputChange={(val) => handleInputByIndex(index)({ model: val })}
									options={dronePresetModels}
									placeholder={`Drone Model`}
								/>
								<label title='Input Drone Weight (kg)' className='fr'>
									<input
										type='number'
										id='drone-weight-input'
										min={0}
										max={20}
										step={0.1}
										value={unitDrones.find((drone) => drone.id === index).weight.toString()}
										onChange={(e) => {
											let result = cleanFloatInput(e, 0, 20, 1);
											handleInputByIndex(index)({ weight: result });
										}}
									/>
									<span style={{ fontWeight: 400, marginTop: "0.7em" }}>kg</span>
								</label>
							</div>
							<button onClick={() => handleDelete(index)} title='Delete Drone'>
								<FontAwesomeIcon icon={faTrashCan} className='icon' />
							</button>
						</li>
					))}
				</ul>
			)}
			<button onClick={handleAddFieldset} className='add-btn' title='Add Drone' data-testid='drone-reg-add-btn'>
				<FontAwesomeIcon icon={faPlus} className='icon' />
			</button>
		</div>
	);
}
