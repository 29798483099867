import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";

export const ThemeContext = createContext(null);

export const ThemeProvider = ({ children }) => {
	const [isDark, setDarkTheme] = useState(false);

	const brightColors = useMemo(() => {
		return {
			bright1: "#2f4b7c",
			bright2: "#665191",
			bright3: "#a05195",
			bright4: "#d45087",
			bright5: "#ff7c43",
			bright6: "#ffa600",
			bright7: "#00876c",
			bright8: "#60a86d",
			bright9: "#ffeb59",
			bright10: "#a8c671",
			bright11: "#e05b4d",
			bright12: "#d43d51",
		};
	}, []);

	const darkColors = useMemo(() => {
		return {
			bgColor: "#152b4f",
			secondaryBgColor: "#172137",
			fontColor: "white",
			iconColor: "white",
			primary: "#b0d0ed",
			secondary: "#b9d2e9",
			tertiary: "#adbdce",
			tertiaryBgColor: "#2C343E",
			clock: "#c9c3ed",
			header: "#152b4f",
			...brightColors,
		};
	}, [brightColors]);

	const lightColors = useMemo(() => {
		return {
			bgColor: "#d9e6f6",
			secondaryBgColor: "#ffffff",
			fontColor: "#1b3968",
			iconColor: "white",
			primary: "#b0d0ed",
			secondary: "#98a8b8",
			tertiary: "#6d7073",
			tertiaryBgColor: "#ffffff",
			clock: "#20175a",
			header: "#152b4f",
			...brightColors,
		};
	}, [brightColors]);

	const toggleDarkTheme = useCallback((isDark) => {
		setDarkTheme(isDark);
	}, []);

	const updateStoredTheme = (isDark) => {
		if (isDark) {
			document.documentElement.setAttribute("data-theme", "dark"); //for css
			localStorage.setItem("theme", "dark");
		} else {
			document.documentElement.setAttribute("data-theme", "light"); //for css
			localStorage.setItem("theme", "light");
		}
	};

	useEffect(() => {
		window.localStorage.getItem("theme") === "dark" ? setDarkTheme(true) : setDarkTheme(false);
	}, []);

	useEffect(() => {
		isDark ? updateStoredTheme(true) : updateStoredTheme(false);
	}, [isDark]);

	let themeColors = useMemo(() => (isDark ? darkColors : lightColors), [darkColors, isDark, lightColors]);

	return <ThemeContext.Provider value={{ isDark, toggleDarkTheme, themeColors }}>{children}</ThemeContext.Provider>;
};

export const useTheme = () => {
	const { isDark, toggleDarkTheme, themeColors } = useContext(ThemeContext);
	return { isDark, toggleDarkTheme, themeColors };
};

// USAGE

// //top-level
// <ThemeProvider Theme={selectedTheme}>
// ....
// </>

// //child
// const { selectedTheme, setSelectedTheme } = useTheme();

//selection of Theme onClick={()=>setSelectedTheme(ThemeID)}
