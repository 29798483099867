import { useEffect, useReducer, useRef, useState } from "react";
import { faEye, faEyeSlash, faSpinner, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logoSq from "assets/nact-logo-transparent-sq-glow.png";
import BgCircuit from "components/_GENERAL/BgCircuit";
import ErrorModal from "components/_GENERAL/ErrorModal";
import DroneRegistration from "components/pagesDRONEREG/DroneRegistration";
import Timer from "components/pagesLOGIN/Timer";
import { LOGIN, MAIN, REGISTER_DRONES } from "constants/constants";
import { useThrottle } from "hooks/useThrottle";
import { httpPasswordReset, httpRegisterUser, httpSendOTP, httpSignInUser, httpVerifyOTP } from "requests/requests";
import { validateFormData } from "utils/helpers";
import "./Login.css";
import { useToast } from "hooks/useToast";

const formReducer = (state, event) => {
	return {
		...state,
		[event.field]: { value: event.value, error: event.error },
	};
};

export default function Login({
	getAndSetAllUnits,
	handleRouteChange,
	route,
	unitsWithNoDrones,
	handleErr,
	handleLogout,
}) {
	const [passwordShown, setPasswordShown] = useState(false);
	const [loginRoute, setLoginRoute] = useState("signin");
	const [formData, setFormData] = useReducer(formReducer, {
		email: { error: true },
	});
	const [errorMessage, setErrorMessage] = useState("");
	const [isPending, setIsPending] = useState(false);
	const [userID, setUserID] = useState("");
	const [otp, setOtp] = useState("");
	const [otpCounter, setOtpCounter] = useState(0);
	const throttledSendOTP = useThrottle(httpSendOTP, 1000, { leading: true, trailing: false });
	const throttledVerifyOTP = useThrottle(httpVerifyOTP, 1000, { leading: true, trailing: false });

	let passwordRef = useRef(null); // to clear password field on route change

	let { showToast } = useToast();

	useEffect(() => {
		errorMessage && handleErr(errorMessage, () => setErrorMessage(""));
	}, [errorMessage, handleErr]);

	const handleInputChange = (event) => {
		let hasError = !validateFormData(event.target.value, event.target.id);

		if (hasError) {
			event.currentTarget.classList.add("error");
		} else {
			event.currentTarget.classList.remove("error");
		}
		setFormData({
			field: event.target.id,
			value: event.target.value,
			error: hasError,
		});
	};

	// API limit number of sign in requests to 5 per day
	const handleSignIn = () => {
		if (
			!formData?.email?.value ||
			!formData?.password?.value ||
			formData?.email?.error ||
			formData?.password?.error
		) {
			setErrorMessage("Please check that you have entered your details correctly");
			return;
		}
		setIsPending(true);
		httpSignInUser({
			email: formData?.email?.value.toLowerCase(),
			password: formData?.password?.value,
		})
			.then((json) => {
				if (json.error) {
					setErrorMessage(`${json.error}`);
					setIsPending(false);
					return;
				}
				if (json.success) {
					getAndSetAllUnits();
					handleRouteChange(MAIN);
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const handleRegister = () => {
		if (
			!formData?.email?.value ||
			!formData?.password?.value ||
			!formData?.password2?.value ||
			formData?.email?.error ||
			formData?.password?.error ||
			formData?.password2?.error
		) {
			setErrorMessage("Please check that you have entered your details correctly");
			return;
		}
		if (formData?.password2?.value !== formData?.password?.value) {
			setErrorMessage("Please check that your passwords match");
			return;
		}
		setIsPending(true);
		httpRegisterUser({
			email: formData?.email?.value.toLowerCase(),
			password: formData?.password?.value,
		})
			.then((json) => {
				if (json.error) {
					setErrorMessage(`${json.error}`);
					setIsPending(false);
					return;
				}
				if (json.success) {
					httpSignInUser({
						email: formData?.email?.value.toLowerCase(),
						password: formData?.password?.value,
					})
						.then((json) => {
							if (json.success) {
								getAndSetAllUnits();
								handleRouteChange(MAIN);
							}
						})
						.catch((error) => {
							console.error(error);
						});
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

	// API limit number of OTP requests to 5 per email per day
	const handleGetOTP = async () => {
		if (!formData?.email?.value || formData?.email?.error) {
			setErrorMessage("Please check that you have entered a valid email address.");
			return;
		}
		setIsPending(true);
		try {
			let res = await throttledSendOTP({
				email: formData.email.value.toLowerCase(),
			});
			setIsPending(false);
			if (res.error) {
				throw new Error(res.error);
			}
			let data = await res.json();

			let remainingAttempts = Number(res.headers.get("X-RateLimit-Remaining"));

			if (remainingAttempts && remainingAttempts <= 3) {
				setErrorMessage(`You have ${remainingAttempts} OTP request(s) left.`);
			}

			if (data.success) {
				setLoginRoute("verifyOTP");
				setOtpCounter(otpCounter + 1);
				showToast({
					id: `otpsent`,
					style: "success",
					message: `OTP sent to ${formData.email.value}`,
					autoClose: 3000,
				});
				return setUserID(data.userID);
			}
		} catch (error) {
			setErrorMessage(`${error.message}`);
			setIsPending(false);
			setLoginRoute("signin");
		}
	};

	// API limit number of wrong OTP attempts to 3 per token
	const handleVerifyOTP = async () => {
		let containsOnlyNumbers = /^\d+$/.test(otp);
		if (otp.length < 6 || !containsOnlyNumbers) {
			setErrorMessage("Please check that you have entered a valid 6-digit OTP.");
			return;
		}
		setIsPending(true);
		try {
			let res = await throttledVerifyOTP({ userID: userID, otp: otp });
			setIsPending(false);
			if (res.error) {
				setErrorMessage(`${res.error}`);
				throw new Error(res.error);
			}
			if (res.success) {
				setLoginRoute("passwordReset");
				return;
			}
		} catch (error) {
			setIsPending(false);
			// setLoginRoute("signin");
		}
	};

	// API limit number of password reset requests to 5 per email per day
	const handlePasswordReset = async () => {
		if (
			!formData?.password?.value ||
			!formData?.password2?.value ||
			formData?.password?.error ||
			formData?.password2?.error
		) {
			setErrorMessage("Please check that you have entered your passwords correctly");
			return;
		}
		setIsPending(true);
		try {
			let res = await httpPasswordReset({
				userID: userID,
				otp: otp,
				newPassword: formData.password.value,
			});
			setIsPending(false);
			if (res.error) {
				return setErrorMessage(`${res.error}`);
			}
			if (res.success) {
				setLoginRoute("signin");
				passwordRef.current.value = "";
				return;
			}
		} catch (error) {
			setIsPending(false);
			console.error(error);
		}
	};

	const handleEnterKey = (e) => {
		e.key === "Enter" && handleSubmit();
	};

	const handleSubmit = () => {
		switch (loginRoute) {
			case "signin":
				return handleSignIn();
			case "register":
				return handleRegister();
			case "getOTP":
				return handleGetOTP();
			case "verifyOTP":
				return handleVerifyOTP();
			case "passwordReset":
				return handlePasswordReset();
			default:
				return;
		}
	};

	const handleRouteChangeEvent = (route) => () => {
		setLoginRoute(route);
		setFormData({
			field: "password",
			value: "",
			error: false,
		});
		setFormData({
			field: "password2",
			value: "",
			error: false,
		});
		return passwordRef.current ? (passwordRef.current.value = "") : null;
	};

	let form = () => {
		let content;
		switch (loginRoute) {
			case "register":
				content = {
					header: "Register",
					emailInput: true,
					passwordInput: true,
					password2Input: true,
					forgotPassword: false,
					submitBtnText: "Register",
					footer: "Already have an account? ",
					footerBtnText: "Sign In",
					handleFooterClick: handleRouteChangeEvent("signin"),
				};

				return content;
			case "getOTP":
				content = {
					header: "Forgot Password",
					emailInput: true,
					passwordInput: false,
					password2Input: false,
					forgotPassword: false,
					submitBtnText: "Get OTP",
					footer: "Remembered your password? ",
					footerBtnText: "Sign In",
					handleFooterClick: handleRouteChangeEvent("signin"),
				};
				return content;
			case "verifyOTP":
				content = {
					header: "Forgot Password",
					submitBtnText: "Verify OTP",
					footer: "Didn't receive an OTP? ",
					footerBtnText: "Resend OTP",
					handleFooterClick: handleGetOTP,
				};
				return content;
			case "passwordReset":
				content = {
					header: "Reset Password",
					passwordInput: true,
					password2Input: true,
					submitBtnText: "Reset Password",
					footer: "Remembered your password? ",
					footerBtnText: "Sign In",
					handleFooterClick: handleRouteChangeEvent("signin"),
				};
				return content;
			default:
				//signin
				content = {
					header: "Sign In",
					emailInput: true,
					passwordInput: true,
					forgotPassword: true,
					submitBtnText: "Sign In",
					footer: "New User? ",
					footerBtnText: "Register",
					handleFooterClick: handleRouteChangeEvent("register"),
				};
				return content;
		}
	};

	return (
		<>
			<BgCircuit opacity={0.6} animDuration={"20s"} bgColor={"transparent"} />
			<article className='fc login'>
				<div className='fc body'>
					{route === LOGIN && (
						<div className='sub-section login fc'>
							<main className='fc'>
								<div className='logo' title='Smart Tethers' style={{ width: "3rem" }}>
									<img src={logoSq} alt='logo' />
								</div>
								<h1>{form().header}</h1>

								{form().emailInput && (
									<input
										type='email'
										name='email'
										id='email'
										data-testid='email'
										placeholder='Email'
										onChange={handleInputChange}
										onKeyDown={handleEnterKey}
									/>
								)}
								{form().passwordInput && (
									<div className='password-wrapper'>
										<input
											type={passwordShown ? "text" : "password"}
											id='password'
											data-testid='password'
											placeholder={loginRoute === "passwordReset" ? "New password" : "Password"}
											onChange={handleInputChange}
											autoComplete='off'
											ref={passwordRef}
											onKeyDown={handleEnterKey}
										/>
										<FontAwesomeIcon
											className='password-show-icon'
											onClick={() => setPasswordShown((passwordShown) => !passwordShown)}
											icon={passwordShown ? faEye : faEyeSlash}
											tabIndex='0'
											onKeyDown={(e) => {
												e.key === "Enter" &&
													setPasswordShown((passwordShown) => !passwordShown);
											}}
										/>
										{form().forgotPassword && (
											<button
												id='password-forgot'
												data-testid='password-forgot'
												onClick={() => setLoginRoute("getOTP")}
												onKeyDown={(e) => e.key === "Enter" && setLoginRoute("getOTP")}
											>
												Forgot password?
											</button>
										)}
									</div>
								)}

								{form().password2Input && (
									<div className='password-wrapper'>
										<div
											role='alert'
											className={`${formData?.password?.error ? "invalid" : ""} ${
												formData?.password2?.value !== formData?.password?.value
													? "no-match"
													: ""
											} `}
										></div>
										<input
											type={passwordShown ? "text" : "password"}
											id='password2'
											data-testid='password2'
											placeholder='Confirm Password'
											onChange={handleInputChange}
											autoComplete='off'
											onKeyDown={handleEnterKey}
										/>
										<FontAwesomeIcon
											className='password-show-icon'
											onClick={() => setPasswordShown((passwordShown) => !passwordShown)}
											icon={passwordShown ? faEye : faEyeSlash}
											role='button'
											tabIndex='0'
											onKeyDown={(e) => {
												e.key === "Enter" &&
													setPasswordShown((passwordShown) => !passwordShown);
											}}
										/>
									</div>
								)}

								{loginRoute === "verifyOTP" && (
									<>
										<button
											id='otp-back-to-signin-btn'
											data-testid='otp-back-to-signin-btn'
											title='Back to Sign In'
											className='clickable'
											onClick={() => setLoginRoute("signin")}
										>
											<FontAwesomeIcon icon={faArrowLeft} className='icon' />
										</button>
										<input
											type='text'
											name='otp'
											id='otp-input'
											data-testid='otp-input'
											placeholder='6-digit OTP'
											maxLength='6'
											onChange={(e) => setOtp(e.target.value)}
											onKeyDown={handleEnterKey}
										/>
										<Timer mins={3} reset={otpCounter} />
									</>
								)}

								<button
									className='submit'
									id='login-submit'
									data-testid='login-submit'
									onClick={() => handleSubmit()}
									onKeyDown={handleEnterKey}
								>
									{isPending && (
										<FontAwesomeIcon
											icon={faSpinner}
											className='icon'
											style={{
												animation: "rotateR 1s ease-out infinite",
												color: "white",
											}}
										/>
									)}
									{!isPending && form().submitBtnText}
								</button>

								<footer>
									{form().footer}
									<button
										className='clickable'
										data-testid='footer-btn'
										onClick={form().handleFooterClick}
										onKeyDown={(e) => e.key === "Enter" && form().handleFooterClick}
									>
										{form().footerBtnText}
									</button>
								</footer>
							</main>
						</div>
					)}
					{route === REGISTER_DRONES && (
						<ErrorModal>
							<DroneRegistration
								units={unitsWithNoDrones}
								handleRegComplete={getAndSetAllUnits}
								handleLogout={handleLogout}
							/>
						</ErrorModal>
					)}
				</div>
			</article>
		</>
	);
}
