import { useEffect, useRef, useState } from "react";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useClickAwayListener } from "hooks/useClickAwayListener";
import { useThrottle } from "hooks/useThrottle";
import "./Dropdown.css";

function Dropdown({
	id,
	contentList,
	defaultOption,
	extSelectionHandler = null,
	customMsgs = null,
	title = "",
	multiSelect = false,
	multiSelectedItems = [],
}) {
	const noItems = customMsgs?.noItems ?? "";
	const headerPrefix = customMsgs?.headerPrefix ?? "";
	const headerSuffix = customMsgs?.headerSuffix ?? "";
	const itemPrefixArray = customMsgs?.itemPrefixArray ?? [];
	const itemSuffixArray = customMsgs?.itemSuffixArray ?? [];
	const [isOpen, setIsOpen] = useState(false);
	const [activeIndex, setActiveIndex] = useState(0);
	const [currentSelection, setCurrentSelection] = useState("");
	const [selectedItems, setSelectedItems] = useState(multiSelectedItems);
	const scrollRef = useRef(null);
	const clickAwayRef = useClickAwayListener(() => {
		setIsOpen(false);
	});
	const throttledSelectionHandler = useThrottle(extSelectionHandler, 1000);

	//if defaultoption changes, update current selection
	useEffect(() => {
		setCurrentSelection(defaultOption);
	}, [defaultOption]);

	//if multiSelectedItems changes, update selected items
	useEffect(() => {
		multiSelectedItems.length && setSelectedItems(multiSelectedItems);
	}, [multiSelectedItems]);

	// on init, set the default selected option
	useEffect(() => {
		if (!currentSelection) {
			setCurrentSelection(defaultOption);
		}
	}, [currentSelection, defaultOption]);

	// when using keydown, the dropdown scrolls to the active item
	useEffect(() => {
		activeIndex && scrollRef.current && scrollRef.current.scrollIntoView({ block: "nearest", behavior: "smooth" });
	}, [activeIndex]);

	// on open, default scroll to the active item
	useEffect(() => {
		if (isOpen) {
			setActiveIndex(contentList.indexOf(defaultOption) === -1 ? 0 : contentList.indexOf(defaultOption));
			scrollRef.current && scrollRef.current.scrollIntoView({ block: "nearest", behavior: "smooth" });
		}
	}, [contentList, defaultOption, isOpen]);

	const handleItemClick = (item) => {
		if (String(currentSelection) !== String(item)) {
			setCurrentSelection(item);
			setActiveIndex(contentList.indexOf(item));
			throttledSelectionHandler(item);
		}
		setIsOpen(false);
	};

	const handleMultiSelect = (item) => {
		let newItems = selectedItems.includes(item)
			? selectedItems.filter((i) => i !== item)
			: [...selectedItems, item];

		setSelectedItems(newItems);
		setCurrentSelection(`${newItems.length} selected`);
		throttledSelectionHandler(newItems);
	};

	const handleKeyDown = (e) => {
		e.preventDefault();
		if (e.key === "Enter" && !multiSelect) {
			setIsOpen(!isOpen);
			return isOpen ? handleItemClick(contentList[activeIndex]) : null;
		} else if (e.key === "Enter" && multiSelect) {
			return handleMultiSelect(contentList[activeIndex]);
		} else if (e.key === "ArrowUp") {
			if (activeIndex === 0) {
				return;
			}
			setActiveIndex(activeIndex - 1);
		} else if (e.key === "ArrowDown") {
			if (activeIndex === contentList.length - 1) {
				return;
			}
			return isOpen ? setActiveIndex(activeIndex + 1) : setIsOpen(!isOpen);
		} else if (e.key === "Tab") {
			if (e.target.className === "dropdown-header" || activeIndex === contentList.length - 1) {
				setIsOpen(!isOpen);
				return;
			}

			setActiveIndex(activeIndex + 1);
		}
	};

	const Checkbox = ({ handleChange, checked }) => {
		const maxChar = contentList.reduce((a, b) => (a.length > b.length ? a : b)).length;

		return (
			<label className='checkbox-dropdown' style={{ "--label-width": `${maxChar}em` }}>
				<input type='checkbox' checked={checked} onChange={handleChange}></input>
			</label>
		);
	};

	return (
		<div className='dropdown' id={id} data-testid={id} ref={clickAwayRef} onKeyDown={handleKeyDown} title={title}>
			<div
				className='dropdown-header'
				onClick={() => setIsOpen(!isOpen)}
				tabIndex='0'
				title={`${headerPrefix}${currentSelection}${headerSuffix}`}
			>
				{!contentList.length && (
					<>
						<span className='no-item'>{noItems}</span>
					</>
				)}
				{!!contentList.length && currentSelection && (
					<>
						<span className='header-prefix'>{headerPrefix}</span>
						<span className='header-body'>{currentSelection}</span>
						<span className='header-suffix'>{headerSuffix}</span>
					</>
				)}
				<FontAwesomeIcon icon={faChevronRight} className={`icon-dropdown ${isOpen && "open"}`} />
			</div>

			<div className={`dropdown-body ${contentList.length && isOpen && "open"}`}>
				{contentList.map((item, i) => (
					<div
						className={activeIndex === i ? "dropdown-item active" : "dropdown-item"}
						key={item}
						onClick={() => (multiSelect ? null : handleItemClick(item))}
						id={item}
						tabIndex='0'
						ref={activeIndex === i ? scrollRef : null}
						title={`${itemPrefixArray[i] ?? ""}${item}${itemSuffixArray[i] ?? ""}`}
					>
						{multiSelect && (
							<Checkbox
								checked={selectedItems.includes(item)}
								handleChange={() => {
									handleMultiSelect(item);
								}}
							/>
						)}

						<span className='item-prefix'>{itemPrefixArray[i] ?? ""}</span>
						<span className='item-body'>{item}</span>
						<span className='item-suffix'>{itemSuffixArray[i] ?? ""}</span>
					</div>
				))}
			</div>
		</div>
	);
}

export default Dropdown;
