import { memo } from "react";
import logo from "assets/nact-logo-transparent-bg.svg";
import "./Header.css";

function Header() {
	return (
		<header className='header'>
			<div className='logo' title='NACT Official Website'>
				<a href='https://www.nactengineering.com/' target='blank'>
					<img src={logo} alt='logo' />
				</a>
			</div>
		</header>
	);
}

export default memo(Header);
