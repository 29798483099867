import { useCallback, useEffect, useMemo, useRef } from "react";
import throttle from "lodash/throttle";

/**
 * Throttles a function
 * @param {function} callback - function to throttle
 * @param {number} wait - time to wait between calls
 * @param {object} opts - options, default is { leading: true, trailing: true }. the first call will be executed, then if multiple during wait period, only the last call will be executed
 * @returns {function} throttledCallback - throttled function
 * https://www.developerway.com/posts/debouncing-in-react
 */
export const useThrottle = (callback, wait, opts) => {
	const ref = useRef();
	useEffect(() => {
		ref.current = callback;
	}, [callback]);

	const throttledCallback = useMemo(() => {
		const func = (...args) => {
			return ref.current?.(...args);
		};

		return throttle(func, wait, opts);
	}, [wait, opts]);

	return useCallback((...args) => throttledCallback(...args), [throttledCallback]);
};

//USAGE
// const onChange = () => {
//     console.log("State value:", value);
// };

// const throttledOnChange = useThrottle(onChange,wait, opts);

// return (
//     <input
//         onChange={(e) => {
//         throttledOnChange();
//         setValue(e.target.value);
//     }}
//     value={value}
//     />
// );
