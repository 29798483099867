export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const REGISTER_DRONES = "REGISTER_DRONES";
export const MAIN = "MAIN";
export const PROFILE = "PROFILE";
export const SETTINGS = "SETTINGS";
export const DETECTION = "DETECTION";

export const DASHBOARD = "DASHBOARD";
export const FLIGHTLOGS = "FLIGHTLOGS";
export const CAMERAVIEW = "CAMERAVIEW";
export const LPS = "LPS";

export const RECEIVING = "RECEIVING";
export const CONNECTED = "CONNECTED";
