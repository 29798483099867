const { number, string, format, floor, round, abs } = require("mathjs");

//make arr of 360 zeroes except angleOfSensing = 1
export const makeAngleArr = (angleOfSensing) => {
	const range360zeroes = (start, end, length = end - start) => Array.from({ length }, (_, i) => 0);
	let angleArr = range360zeroes(0, 360);
	angleArr.splice(angleOfSensing - 1, 1, 1);
	return angleArr;
};

/**
 * The function `cleanNumInput` takes an input value, a minimum value, and a maximum value, and returns
 * a cleaned and validated number within the specified range.
 * @param e - The parameter `e` is an event object that represents the event that triggered the
 * function. It is typically passed in when the function is called as an event handler.
 * @param min - The minimum value that the input can have.
 * @param max - The `max` parameter represents the maximum allowed value for the input.
 * @returns The function `cleanNumInput` returns a cleaned and validated number input.
 */
export const cleanNumInput = (e, min, max) => {
	let val = e.target.value ? parseInt(e.target.value) : 0;
	let inputLen = val.toString().length;
	let maxLen = max.toString().length;
	if (inputLen > maxLen) {
		val = parseInt(val.toString().slice(0, maxLen));
	}
	if (val >= min && val <= max) {
		return val;
	} else if (val < min) {
		return min;
	} else {
		return max;
	}
};

/**
 * The cleanFloatInput function takes an input value, a minimum value, a maximum value, and a decimal
 * precision, and returns a cleaned and validated float value within the specified range.
 * @param e - The parameter `e` is an event object that represents the event that triggered the
 * function. It is typically used to access the value of the input element that triggered the event.
 * @param min - The minimum value that the input can have.
 * @param max - The maximum value that the input can have.
 * @param dp - The `dp` parameter stands for "decimal places" and represents the maximum number of
 * decimal places allowed in the input value.
 * @returns The function `cleanFloatInput` returns the cleaned and validated float value.
 */
export const cleanFloatInput = (e, min, max, dp) => {
	let val = e.target.value ? Number(e.target.value) : 0;
	let inputLen = val.toString().length;
	let maxLen = max.toString().length + 1 + dp;

	if (inputLen > maxLen) {
		val = Number(val.toString().slice(0, maxLen));
	}
	if (val >= min && val <= max) {
		let [number, decimal] = val.toString().split(".");
		let decimalVal = decimal ? "." + decimal.slice(0, dp) : "";
		val = Number(`${number}${decimalVal}`);
		return val;
	} else if (val < min) {
		return min;
	} else {
		return max;
	}
};

/**
 * The function adds leading zeros to a value if the value is smaller than a specified number of
 * significant figures.
 * @param value - The value parameter is the number or string that you want to add leading zeros to.
 * @param sf - The parameter "sf" stands for "significant figures". It represents the number of digits
 * that should be present in the final value, including any leading zeros.
 * @returns The function `addLeadingZero` returns a string value.
 */
export const addLeadingZero = (value, sf) => {
	if (value === 0) {
		return "0".repeat(sf);
	}

	const remainder = 10 ** (sf - 1) - number(value);
	if (remainder > 0) {
		const zeroes = "0".repeat(string(remainder).length);
		const roundedValue = round(value, string(remainder).length);
		return `${zeroes}${roundedValue}`;
	}
	return value;
};

/**
 * The function `convertSecondsToTime` takes a number of seconds as input and returns a formatted
 * string representing the equivalent time in hours, minutes, or seconds.
 * @param seconds - The parameter `seconds` is the number of seconds that you want to convert to a time
 * format.
 * @returns a formatted string representing the time in hours, minutes, or seconds, depending on the
 * input value.
 */
export const convertSecondsToTime = (seconds) => {
	let hours = floor(seconds / 3600);
	let minutes = floor(seconds / 60);

	let result, unit;

	if (abs(minutes) >= 60) {
		result = format(hours, { precision: 2 });
		unit = "hr" + (abs(result) > 1 ? "s" : "");
	} else if (abs(seconds) >= 60) {
		result = format(minutes, { precision: 2 });
		unit = "min" + (abs(result) > 1 ? "s" : "");
	} else {
		result = format(seconds, { precision: 2 });
		unit = "s";
	}
	return `${result} ${unit}`;
};

export const convertMinutesToTime = (minutes) => {
	let hours = floor(round(minutes, 0) / 60);
	let result, unit;

	if (abs(minutes) >= 60) {
		result = format(hours, { precision: 2 });
		unit = "hr" + (abs(result) > 1 ? "s" : "");
	} else {
		result = format(minutes, { precision: 2 });
		unit = "min" + (abs(result) > 1 ? "s" : "");
	}
	return `${result} ${unit}`;
};

export const convertHoursToTime = (hours) => {
	let result, unit;
	if (abs(hours) > 72) {
		// 3 days
		result = format(hours / 24, { precision: 2 });
		unit = "day" + (abs(result) > 1 ? "s" : "");
	} else if (abs(hours) > 8760) {
		// 365 days
		result = format(hours / 24 / 365, { precision: 2 });
		unit = "year" + (abs(result) > 1 ? "s" : "");
	} else {
		result = format(hours, { precision: 2 });
		unit = "hr" + (abs(result) > 1 ? "s" : "");
	}
	return `${result} ${unit}`;
};

/**
 * The function `validateFormData` checks if a given value matches a specified type of data (text,
 * email, or password) and returns true if it is valid, and false otherwise.
 * @param value - The value is the input value that needs to be validated. It can be a string or any
 * other data type depending on the input field.
 * @param type - The `type` parameter is a string that represents the type of data being validated. It
 * can have the following values:
 * @returns The function `validateFormData` returns bool `true` if the value passes the validation based on
 * the specified type, and `false` otherwise.
 */
export const validateFormData = (value, type) => {
	// NO VALUE
	if (!value) {
		return false;
	}

	// ALPHANUMERIC (spaces allowed, no other special characters)
	if (type === "text" && !/^[a-zA-Z0-9 ]*$/.test(value)) {
		return false;
	}

	// EMAIL FORMAT
	if (
		type === "email" &&
		!/^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,6})$/.test(value)
	) {
		return false;
	}

	// PASSWORD Minimum 8 characters, at least one uppercase, one lowercase letter and one number. special characters ok
	if (
		(type.toLowerCase().includes("password") || type.toLowerCase().includes("pwd")) &&
		!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(value)
	) {
		return false;
	}

	return true;
};

// -----------------------------------------------------------------

/**
 * The function `convertToCSV` converts an array of objects into a CSV string.
 * @param objArray - The `objArray` parameter is an array of objects that you want to convert to a CSV
 * (Comma-Separated Values) format. Each object in the array represents a row in the CSV, and the
 * properties of the object represent the columns.
 * @returns a string that represents the input array in CSV format.
 */
function convertToCSV(objArray) {
	let array = typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
	let str = "";

	for (let i = 0; i < array.length; i++) {
		let line = "";
		for (let index in array[i]) {
			if (line !== "") line += ",";
			line += array[i][index];
		}

		str += line + "\r\n";
	}
	return str;
}

/**
 * The function `exportCSVFile` exports data as a CSV file with the given headers, items, and file
 * title.
 * @param headers - An array of strings representing the column headers for the CSV file.
 * @param items - The `items` parameter is an array of objects that you want to export to a CSV file.
 * Each object represents a row in the CSV file, and the properties of the object represent the
 * columns.
 * @param fileTitle - The fileTitle parameter is a string that represents the name of the exported CSV
 * file. It is optional and if not provided, the default name will be "export.csv".
 */
export const exportCSVFile = (headers, items, fileTitle) => {
	if (headers) {
		items.unshift(headers);
	}

	// Convert Object to JSON
	let jsonObject = JSON.stringify(items);
	let csv = convertToCSV(jsonObject);

	let exportedFilename = fileTitle + ".csv" || "export.csv";

	let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
	if (navigator.msSaveBlob) {
		// IE 10+
		navigator.msSaveBlob(blob, exportedFilename);
	} else {
		let link = document.createElement("a");
		if (link.download !== undefined) {
			// Browsers that support HTML5 download attribute
			let url = URL.createObjectURL(blob);
			link.setAttribute("href", url);
			link.setAttribute("download", exportedFilename);
			link.style.visibility = "hidden";
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}
};

/**
 * Flatten nested object
 * @ob {obj} nested object
 * @return {object} flattened object
 */
export const flattenObj = (ob) => {
	// The object which contains the
	// final result
	let result = {};

	// loop through the object "ob"
	for (const i in ob) {
		// We check the type of the i using
		// typeof() function and recursively
		// call the function again
		if (typeof ob[i] === "object" && !Array.isArray(ob[i])) {
			const temp = flattenObj(ob[i]);
			for (const j in temp) {
				// Store temp in result
				result[i + "." + j] = temp[j];
			}
		}

		// Else store ob[i] in result directly
		else {
			result[i] = ob[i];
		}
	}
	return result;
};

export function findArrayDifference(arr1, arr2) {
	// Find elements in arr1 that are not present in arr2
	const differenceArr1 = arr1.filter((item) => !arr2.includes(item));

	// Find elements in arr2 that are not present in arr1
	const differenceArr2 = arr2.filter((item) => !arr1.includes(item));

	// Combine both differences into a single array and return it
	return differenceArr1.concat(differenceArr2);
}

export function capitalizeFirstLetter(str) {
	return str.charAt(0).toUpperCase() + str.slice(1);
}

export const convertSecondsToUptime = (value) => {
	value = Math.floor(value);
	//return HH:MM:SS
	//add leading zero to single digit values
	let hours = Math.floor(value / 3600);
	hours = addLeadingZero(hours, 2);
	let minutes = Math.floor((value - hours * 3600) / 60);
	minutes = addLeadingZero(minutes, 2);
	let seconds = value - hours * 3600 - minutes * 60;
	seconds = addLeadingZero(seconds, 2);
	return `${hours}:${minutes}:${seconds}`;
};

export const convertUptimeToSeconds = (uptime) => {
	let timeParts = uptime.split(":");
	let hours = parseInt(timeParts[0], 10);
	let minutes = parseInt(timeParts[1], 10);
	let seconds = parseInt(timeParts[2], 10);

	// Convert to seconds
	return hours * 60 * 60 + minutes * 60 + seconds;
};

export const getLocalTimezone = () => {
	return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export function getOffsetDate(inputDate, offsetHours) {
	return new Date(inputDate.getTime() + offsetHours * 60 * 60 * 1000).toLocaleString("en-GB");
}

export const convertVideoDateTimeToTimezone = (date, time, timezoneOffset) => {
	//convert selected date and filename to Timezone time
	const [hours, minutes, seconds] = time.split(":");
	const [year, month, day] = date.includes("/") ? date.split("/") : date.split("-");
	const newDate = new Date();
	newDate.setFullYear(year);
	newDate.setMonth(month - 1);
	newDate.setDate(day);
	newDate.setHours(hours);
	newDate.setMinutes(minutes);
	newDate.setSeconds(seconds);
	const [convertedDate, convertedTime] = getOffsetDate(newDate, timezoneOffset).split(", ");
	return [convertedDate, convertedTime];
};
