import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { getAuthTokenFromSessionStorage, getUserIDFromToken } from "../requests/requests";

export const UserContext = createContext(null);

export const UserProvider = ({ units, children }) => {
	const [allUnits, setAllUnits] = useState([]);
	const [userID, setUserID] = useState("");

	useEffect(() => {
		const authToken = getAuthTokenFromSessionStorage();
		if (units.length && authToken) {
			// only get userid from token after units
			setAllUnits(units);
			const userID = getUserIDFromToken(authToken);
			setUserID(userID);
		}
	}, [units]);

	const updateUnit = useCallback(
		(unitID, unitKey, update) => {
			let unitIndex = allUnits.findIndex((unit) => unit.unitID === unitID);
			let updatedUnit = allUnits[unitIndex];
			updatedUnit[unitKey] = update;
			let updatedUnits = [...allUnits];
			updatedUnits[unitIndex] = updatedUnit;
			setAllUnits(updatedUnits);
		},
		[allUnits],
	);

	return <UserContext.Provider value={{ userID, allUnits, updateUnit }}>{children}</UserContext.Provider>;
};

export const useUser = () => {
	const { userID, allUnits, updateUnit } = useContext(UserContext);

	return { userID, allUnits, updateUnit };
};

// USAGE

// //top-level
// <UserProvider user={selectedUser}>
// ....
// </>

// //child
// const { allUnits} = useUser();

//selection of user onClick={()=>setSelectedUser(userID)}
