import { useCallback, useEffect, useRef, useState } from "react";

export default function Timer({ mins, reset }) {
	const [timer, setTimer] = useState(mins + ":00");
	const intervalRef = useRef();

	const decrementTimer = useCallback(() => {
		setTimer((time) => {
			const [mins, secs] = time.split(":").map(Number);
			if (mins === 0 && secs === 0) {
				return time;
			} else if (secs === 0) {
				return mins - 1 + ":59";
			} else {
				return mins + ":" + (secs - 1).toString().padStart(2, "0");
			}
		});
	}, []);

	const resetTimer = useCallback(() => {
		setTimer(mins + ":00");
	}, [mins]);

	useEffect(() => {
		intervalRef.current = setInterval(decrementTimer, 1000);
		return () => clearInterval(intervalRef.current);
	}, [decrementTimer]);

	useEffect(() => {
		resetTimer();
	}, [reset, resetTimer]);

	return <p style={{ margin: 0, color: "var(--tertiary)" }}>{timer}</p>;
}
