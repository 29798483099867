import { decodeToken, isExpired } from "react-jwt";

const API_URL = process.env.REACT_APP_EXPRESS_API || ``;

const saveAuthTokenInSessionStorage = (token) => {
	window.sessionStorage.setItem("token", token);
};

const getAuthTokenFromSessionStorage = () => {
	return window.sessionStorage.getItem("token");
};

const deleteAuthTokenFromSessionStorage = () => {
	window.sessionStorage.removeItem("token");
};

const deleteAllTokensFromSessionStorage = () => {
	window.sessionStorage.clear();
};

const getUserIDFromToken = (token) => {
	const userID = decodeToken(token);
	const isTokenExpired = isExpired(token);
	return isTokenExpired ? "" : userID.userID;
};

const validationErrStatus = (response) => {
	if (response.status === 422) {
		throw Error("Please check that all fields are filled in correctly.");
	}
};

const sessionAuthErrStatus = (response) => {
	if (response.status === 401 || response.status === 403) {
		deleteAuthTokenFromSessionStorage();
		throw Error("Your session has timed out. Please refresh or login again.");
	}
};
const serverErrStatus = (response) => {
	if (response.status === 500) {
		throw Error("Server error. Please refresh the page or try again later.");
	}
};

// --------------------------------------------------------------------------------------------

async function httpSignInUser(formData) {
	try {
		const reqOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json; charset=UTF-8" },
			body: JSON.stringify(formData),
			credentials: "include",
		};
		const response = await fetch(`${API_URL}/login/user`, reqOptions);
		try {
			validationErrStatus(response);
			serverErrStatus(response);
		} catch (err) {
			return { error: err.message };
		}

		const data = await response.json();

		let retryAfter = response.headers.get("Retry-After");
		let remainingAttempts = Number(response.headers.get("X-RateLimit-Remaining"));

		if (response.status === 401 && remainingAttempts && remainingAttempts <= 3) {
			return {
				error: `Invalid login. Please check your username and password combination. You have ${remainingAttempts} attempt(s) left. `,
			};
		}
		if (response.status === 401) {
			return {
				error: "Invalid login. Please check your username and password combination.",
			};
		}
		if (response.status === 429) {
			return {
				error: `Too many failed login attempts, you may try again in ${retryAfter}.`,
			};
		}

		if (!response.ok) {
			return { error: "Unable to sign in, please try again later or check your connection." };
		}
		saveAuthTokenInSessionStorage(data.token);
		return data; //{success": true,"userID": "1234567890","token": "eyJhbGciOHAiOjE..."}
	} catch (err) {
		deleteAllTokensFromSessionStorage();
		console.error(err.message);
		return { error: "Network Error. Please check your connection or try again later." };
	}
}

async function httpRegisterUser(formData) {
	try {
		const reqOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json; charset=UTF-8" },
			body: JSON.stringify(formData),
			credentials: "include",
		};

		const response = await fetch(`${API_URL}/login/new/`, reqOptions);

		try {
			validationErrStatus(response);
			serverErrStatus(response);
		} catch (err) {
			return { error: err.message };
		}

		const data = await response.json();

		if (response.status === 403) {
			return {
				error: "No records found. Please contact your sales representative for assistance.",
			};
		}
		if (response.status === 401) {
			// already exists
			return {
				error: "Thank you for registering, an email has been sent to this account for confirmation",
			};
		}
		if (response.ok) {
			return data; //{success": true,"userID": "1234567890"}
		} else {
			return { error: "Unable to register, please try again later or check your connection." };
		}
	} catch (err) {
		console.error(err.message);
		return { error: "Network Error. Please check your connection or try again later." };
	}
}

async function httpSaveNewDrones(formData) {
	try {
		const authToken = getAuthTokenFromSessionStorage();

		const results = await Promise.all(
			formData.map(async (unit) => {
				const reqOptions = {
					method: "POST",
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${authToken}`,
					},
					body: JSON.stringify(unit.drones),
					credentials: "include",
				};
				let unitID = unit.unitID;
				let response = await fetch(`${API_URL}/unit/new/drones/${unitID}`, reqOptions);
				try {
					validationErrStatus(response);
					serverErrStatus(response);
					sessionAuthErrStatus(response);
				} catch (err) {
					return { error: err.message };
				}
				const data = await response.json();
				if (response.ok) {
					return data.success; //{ success: true }
				} else {
					return { error: "Unable to register drones, please try again or check your connection." };
				}
			}),
		);

		return results;
	} catch (err) {
		console.error(err.message);
		return { error: "Network Error. Please check your connection or try again later." };
	}
}

async function httpSaveDroneCableID(unitID, droneCableArr) {
	try {
		const authToken = getAuthTokenFromSessionStorage();

		const reqOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: `Bearer ${authToken}`,
			},
			body: JSON.stringify(droneCableArr),
			credentials: "include",
		};

		const response = await fetch(`${API_URL}/unit/cable/drones/${unitID}`, reqOptions);
		try {
			validationErrStatus(response);
			serverErrStatus(response);
			sessionAuthErrStatus(response);
		} catch (err) {
			return { error: err.message };
		}
		const data = await response.json();
		if (!response.ok) {
			return { error: "Unable to save drones-cableID , please try again or check your connection." };
		}
		return data.success; //{ success:true }
	} catch (err) {
		console.error(err.message);
		return { error: "Network Error. Please check your connection or try again later." };
	}
}

async function httpDeleteDrones(data) {
	let { droneIDs, unitID } = data;
	try {
		const authToken = getAuthTokenFromSessionStorage();

		const reqOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: `Bearer ${authToken}`,
			},
			body: JSON.stringify({ droneIDs }),
			credentials: "include",
		};
		const response = await fetch(`${API_URL}/unit/delete/drones/${unitID}`, reqOptions);
		try {
			validationErrStatus(response);
			serverErrStatus(response);
			sessionAuthErrStatus(response);
		} catch (err) {
			return { error: err.message };
		}
		const data = await response.json();
		if (!response.ok) {
			return { error: "Unable to delete drones , please try again or check your connection." };
		}
		return data.success; //{ success:true }
	} catch (err) {
		console.error(err.message);
		return { error: "Network Error. Please check your connection or try again later." };
	}
}

async function httpLogOutUser() {
	try {
		const authToken = getAuthTokenFromSessionStorage();
		deleteAllTokensFromSessionStorage();

		const reqOptions = {
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: `Bearer ${authToken}`,
			},
			credentials: "include",
		};
		const response = await fetch(`${API_URL}/logout`, reqOptions);

		if (!response.ok) {
			return { error: "Logout error." };
		}
		return { success: true };
	} catch (err) {
		return { error: true };
	}
}

async function httpSendOTP({ email }) {
	try {
		const reqOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json; charset=UTF-8" },
			body: JSON.stringify({ email: email }),
			credentials: "include",
		};
		const response = await fetch(`${API_URL}/user/sendOTP/`, reqOptions);
		try {
			validationErrStatus(response);
			serverErrStatus(response);
		} catch (err) {
			return { error: err.message };
		}

		let retryAfter = response.headers.get("Retry-After");

		if (response.status === 429) {
			return {
				error: `Too many OTP requests, you may try again in ${retryAfter}.`,
			};
		}

		if (response.ok) {
			return response; //{ success: true, userID: userID }
		}
		return { error: "Unable to send OTP, please try again later or check your connection." };
	} catch (err) {
		console.error(err.message);
		return { error: "Network Error. Please check your connection or try again later." };
	}
}

async function httpVerifyOTP({ userID, otp }) {
	try {
		const reqOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json; charset=UTF-8" },
			body: JSON.stringify({ otp: otp }),
			credentials: "include",
		};
		const response = await fetch(`${API_URL}/user/confirmOTP/${userID}`, reqOptions);
		try {
			validationErrStatus(response);
			serverErrStatus(response);
		} catch (err) {
			return { error: err.message };
		}

		let remainingAttempts = Number(response.headers.get("X-RateLimit-Remaining"));

		if (response.status === 429 || remainingAttempts === 0) {
			return { error: `Too many failed attempts. Please request for a new OTP.` };
		}
		if (!response.ok) {
			return { error: "Invalid or expired OTP. Please check that you have entered the code correctly." };
		}
		return { success: true };
	} catch (err) {
		console.error(err.message);
		return { error: "Network Error. Please check your connection or try again later." };
	}
}

async function httpPasswordReset(inputData) {
	let { userID, ...passwordData } = inputData;

	try {
		const reqOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json; charset=UTF-8" },
			body: JSON.stringify({ ...passwordData }),
			credentials: "include",
		};

		const response = await fetch(`${API_URL}/user/resetPwd/${userID}`, reqOptions);
		try {
			validationErrStatus(response);
			serverErrStatus(response);
		} catch (err) {
			return { error: err.message };
		}

		let retryAfter = response.headers.get("Retry-After");
		if (response.status === 429) {
			return {
				error: `You have exceeded the maximum number of password changes per week. You may try again in ${retryAfter}.`,
			};
		}
		if (!response.ok) {
			return { error: "Unable to reset password" };
		}

		return { success: true };
	} catch (err) {
		console.error(err.message);
		return { error: "Network Error. Please check your connection or try again later." };
	}
}

//-----------------------------------------------------------------------------------

//get array of units+specs belonging to user, alphabetical order and return as JSON.
async function httpGetAllUnits() {
	try {
		const authToken = getAuthTokenFromSessionStorage();
		const userID = getUserIDFromToken(authToken);
		const reqOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: `Bearer ${authToken}`,
			},
			credentials: "include",
		};

		const response = await fetch(`${API_URL}/unit/all/${userID}`, reqOptions);
		try {
			validationErrStatus(response);
			serverErrStatus(response);
			sessionAuthErrStatus(response);
		} catch (err) {
			return { error: err.message };
		}
		if (!response.ok || !userID) {
			return { error: "No units found for this user." };
		}
		const data = await response.json(); //{ success: true, units:result }
		const fetchedUnits = await data?.units;
		return fetchedUnits.sort((a, b) => {
			return a.unitID - b.unitID;
		});
	} catch (err) {
		console.error(err.message);
		return { error: "Network Error. Please check your connection or try again later." };
	}
}

async function httpGetFlightSummaries(unitID) {
	try {
		const authToken = getAuthTokenFromSessionStorage();
		const reqOptions = {
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: `Bearer ${authToken}`,
			},
			credentials: "include",
		};
		let response = await fetch(`${API_URL}/flight/${unitID}`, reqOptions);
		try {
			validationErrStatus(response);
			serverErrStatus(response);
			sessionAuthErrStatus(response);
		} catch (err) {
			return { error: err.message };
		}
		let data = await response.json(); //{ success: true, flightSummaries }
		let flightSummaries = data?.flightSummaries;
		if (!response.ok || flightSummaries.length === 0) {
			return { error: "No flight history logged. Take her out on a flight!" };
		}
		return flightSummaries;
	} catch (err) {
		console.error(err.message);
		return { error: "Network Error. Please check your connection or try again later." };
	}
}

// KIV : mongo time series
// get flight logs based on unitID+flight num
async function httpGetFlightLog(unitID, flightNum, latest = 0, range = []) {
	try {
		const authToken = getAuthTokenFromSessionStorage();
		const reqOptions = {
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: `Bearer ${authToken}`,
			},
			credentials: "include",
		};
		let uri = `${API_URL}/flight/${unitID}/${flightNum}/${
			latest ? "?latest=1" : range.length ? `?start=${range[0]}&end=${range[1]}` : ""
		}`;
		let response = await fetch(uri, reqOptions);
		try {
			validationErrStatus(response);
			serverErrStatus(response);
			sessionAuthErrStatus(response);
		} catch (err) {
			return { error: err.message };
		}
		let data = await response.json(); //{ success: true, flightLog:flightLogWithLPS }
		let flightLog = await data?.flightLog;
		if (!response.ok || flightLog.length === 0) {
			return { error: "No flight history logged. Take her out on a flight!" };
		}
		return flightLog;
	} catch (err) {
		console.error(err.message);
		return { error: "Network Error. Please check your connection or try again later." };
	}
}

//-----------------------------------------------------------------------------------

async function httpGetUnitProfiles() {
	try {
		const authToken = getAuthTokenFromSessionStorage();
		const userID = getUserIDFromToken(authToken);
		const reqOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: `Bearer ${authToken}`,
			},
			credentials: "include",
		};
		let response = await fetch(`${API_URL}/user/profile/${userID}`, reqOptions);
		try {
			validationErrStatus(response);
			serverErrStatus(response);
			sessionAuthErrStatus(response);
		} catch (err) {
			return { error: err.message };
		}
		let data = await response.json(); //{ success: true, profile }
		let profile = await data?.profile;
		if (!response.ok || profile.length === 0) {
			return { error: "No profile data found" };
		}
		return profile;
	} catch (err) {
		console.error(err.message);
		return { error: "Network Error. Please check your connection or try again later." };
	}
}

// post new user input object to DB.
async function httpSaveUserInputs(unitID, newInput) {
	try {
		const authToken = getAuthTokenFromSessionStorage();
		const reqOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: `Bearer ${authToken}`,
			},
			body: JSON.stringify(newInput),
			credentials: "include",
		};

		const response = await fetch(`${API_URL}/unit/input/${unitID}`, reqOptions);
		try {
			validationErrStatus(response);
			serverErrStatus(response);
			sessionAuthErrStatus(response);
		} catch (err) {
			return { error: err.message };
		}
		const data = await response.json();
		if (!response.ok) {
			return { error: "Error connecting to server. Please try again later." };
		}
		return data;
	} catch (err) {
		console.error(err.message);
		return { error: "Network Error. Please check your connection or try again later." };
	}
}

async function httpGetAllCameras(unitID) {
	try {
		const authToken = getAuthTokenFromSessionStorage();
		const reqOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: `Bearer ${authToken}`,
			},
			credentials: "include",
		};
		let response = await fetch(`${API_URL}/unit/cameras/${unitID}`, reqOptions);
		try {
			validationErrStatus(response);
			serverErrStatus(response);
			sessionAuthErrStatus(response);
		} catch (err) {
			return { error: err.message };
		}
		let data = await response.json(); //{ success:true, cameras: cameraObjects }
		let cameras = await data?.cameras;

		if (!response.ok || !cameras.length) {
			return {
				error: `Unit ${unitID} does not have any cameras configured. Please contact your sales representative to add cameras to your system.`,
			};
		}
		return cameras;
	} catch (err) {
		console.error(err.message);
		return { error: "Network Error. Please check your connection or try again later." };
	}
}

async function httpGetAllVideos(camID) {
	try {
		const authToken = getAuthTokenFromSessionStorage();
		const reqOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: `Bearer ${authToken}`,
			},
			credentials: "include",
		};
		let response = await fetch(`${API_URL}/camera/videos/${camID}`, reqOptions);
		try {
			validationErrStatus(response);
			serverErrStatus(response);
			sessionAuthErrStatus(response);
		} catch (err) {
			return { error: err.message };
		}
		let data = await response.json(); //{ success: true, videos }
		let videos = await data?.videos;
		if (!response.ok || !videos.length) {
			return { error: `No video recordings found for camera ${camID}` };
		}
		return videos;
	} catch (err) {
		console.error(err.message);
		return { error: "Network Error. Please check your connection or try again later." };
	}
}

const httpMoveCamera = async (movement, unitID, camera) => {
	try {
		let { action, speed, pan, tilt, zoom } = movement;
		let { camID, camSpecs } = camera;
		pan = pan ? pan : 0;
		tilt = tilt ? tilt : 0;
		zoom = zoom ? zoom : 0;
		const authToken = getAuthTokenFromSessionStorage();
		const reqOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: `Bearer ${authToken}`,
			},
			body: JSON.stringify({ camID, camSpecs }),
			credentials: "include",
		};

		const response = await fetch(
			`${API_URL}/camera/move/${unitID}/${action}/${speed}/${pan}-${tilt}-${zoom}`,
			reqOptions,
		);
		try {
			validationErrStatus(response);
			serverErrStatus(response);
			sessionAuthErrStatus(response);
		} catch (err) {
			return { error: err.message };
		}
		if (!response.ok) {
			return {
				error: "Error connecting to camera for movement. Please check your connection or try again later.",
			};
		}
		const data = await response.json();

		return data; // {success:bool, continuous:bool, status: {pan,tilt} }
	} catch (err) {
		console.error(err.message);
		return { error: "Network Error. Please check your connection or try again later." };
	}
};
// const httpUpdateCameraStatus = async (camID, cameraStatus) => {
// 	try {
// 		const authToken = getAuthTokenFromSessionStorage();
// 		const reqOptions = {
// 			method: "POST",
// 			headers: {
// 				"Content-Type": "application/json; charset=UTF-8",
// 				Authorization: `Bearer ${authToken}`,
// 			},
// 			body: JSON.stringify({ cameraStatus }),
// 			credentials: "include",
// 		};

// 		const response = await fetch(`${API_URL}/camera/update/${camID}`, reqOptions);
// 		try {
// 	validationErrStatus(response);
// 	serverErrStatus(response);
// 	sessionAuthErrStatus(response);
// } catch (err) {
// 	return { error: err.message };
// }
// 		if (!response.ok) {
// 			return {error:"Error updating camera status. Please check your connection or try again later."}
// 		}
// 		const data = await response.json();

// 		return data; // {success:true}
// 	} catch (err) {
// 		return { error: 'Network Error. Please check your connection or try again later.' };
// 	}
// };

const httpGetInitialPanTilt = async (movement, unitID, camera) => {
	try {
		const { action } = movement;
		let { camID, camSpecs } = camera;
		const authToken = getAuthTokenFromSessionStorage();
		const reqOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: `Bearer ${authToken}`,
			},
			body: JSON.stringify({ camID, camSpecs }),
			credentials: "include",
		};

		const response = await fetch(`${API_URL}/camera/move/${unitID}/${action}/0/0-0-0`, reqOptions);
		try {
			validationErrStatus(response);
			serverErrStatus(response);
			sessionAuthErrStatus(response);
		} catch (err) {
			return { error: err.message };
		}

		if (!response.ok) {
			return { error: "Error getting camera status. Please check your connection or try again later." };
		}
		const data = await response.json(); // { success:true, status: [initialPan, initialTilt] }
		const status = await data.status;
		return status;
	} catch (err) {
		console.error(err.message);
		return { error: "Network Error. Please check your connection or try again later." };
	}
};

const httpDownloadVideo = async (videoPaths) => {
	try {
		const authToken = getAuthTokenFromSessionStorage();

		const reqOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: `Bearer ${authToken}`,
			},
			credentials: "include",
			body: JSON.stringify({ videoPaths }),
		};

		let response = await fetch(`${API_URL}/camera/download`, reqOptions);
		try {
			validationErrStatus(response);
			serverErrStatus(response);
			sessionAuthErrStatus(response);
		} catch (err) {
			return { error: err.message };
		}
		let data = await response.json(); //{ success:true, downloadLink }
		if (!response.ok || data.error) {
			return { error: "No video data found." };
		}
		return data.downloadLink;
	} catch (err) {
		console.error(err.message);
		return { error: "Network Error. Please check your connection or try again later." };
	}
};

async function httpGetAllSnapshots(camID) {
	try {
		const authToken = getAuthTokenFromSessionStorage();
		const reqOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: `Bearer ${authToken}`,
			},
			credentials: "include",
		};
		let response = await fetch(`${API_URL}/camera/snapshots/${camID}`, reqOptions);
		try {
			validationErrStatus(response);
			serverErrStatus(response);
			sessionAuthErrStatus(response);
		} catch (err) {
			return { error: err.message };
		}
		let data = await response.json(); //{ success: true, snapshots }
		let snapshots = await data?.snapshots;
		if (!response.ok || !snapshots.length) {
			return { error: `No snapshots found for camera ${camID}` };
		}
		let snapshotsWithSrc = snapshots.map((snapshot) => {
			return {
				src: `${snapshot.src}`,
				width: 320,
				height: 180,
				// tags: [{ value: "Human", title: "Human" }],
				caption: snapshot.filename,
			};
		});

		return snapshotsWithSrc;
	} catch (err) {
		console.error(err.message);
		return { error: "Network Error. Please check your connection or try again later." };
	}
}

export {
	getAuthTokenFromSessionStorage,
	getUserIDFromToken,
	deleteAuthTokenFromSessionStorage,
	deleteAllTokensFromSessionStorage,
	httpRegisterUser,
	httpSignInUser,
	httpLogOutUser,
	httpGetFlightLog,
	httpGetAllUnits,
	httpSaveUserInputs,
	httpGetFlightSummaries,
	httpMoveCamera,
	// httpUpdateCameraStatus,
	httpGetInitialPanTilt,
	httpDownloadVideo,
	httpSaveNewDrones,
	httpSaveDroneCableID,
	httpDeleteDrones,
	httpSendOTP,
	httpVerifyOTP,
	httpPasswordReset,
	httpGetUnitProfiles,
	httpGetAllCameras,
	httpGetAllVideos,
	httpGetAllSnapshots,
};
