"use client";

import { useEffect, useState, Suspense, lazy, useCallback } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomModal from "components/_GENERAL/CustomModal";
import ErrorModal from "components/_GENERAL/ErrorModal";
import Header from "components/header/Header";
import Login from "components/pagesLOGIN/Login";
import Splash from "components/pagesSPLASH/Splash";
import SideBar from "components/sidebar/SideBar";
import { LOGIN, REGISTER_DRONES, LOGOUT, MAIN, DASHBOARD } from "constants/constants";
import { useFetch } from "hooks/useFetch";
import { ThemeProvider } from "hooks/useTheme";
import { useToast } from "hooks/useToast";
import { UserProvider } from "hooks/useUser";
import { WebsocketProvider } from "hooks/useWebSocket";
import { deleteAuthTokenFromSessionStorage, httpGetAllUnits, httpLogOutUser } from "requests/requests";
import "./App.css";
import "./Responsive.css";

const AppLogic = lazy(() => import("components/_LAYOUT/AppLogic"));

function App() {
	// ----------- STATE ----------------------------------
	const [route, setRoute] = useState(""); // LOGIN, MAIN, LOGOUT
	const [page, setPage] = useState(DASHBOARD); // DASHBOARD, FLIGHTLOGS, CAMERAVIEW, PROFILE, SETTINGS
	const [allUnits, setAllUnits] = useState([]);
	let { apiFetch, apiLoading, apiError, apiData, resetFetchStatus } = useFetch();
	let unitsWithNoDrones = allUnits.filter((unit) => unit.drones.length === 0);
	const [prog, setProg] = useState(0);
	const { resetOnceOnlyToast, removeToast } = useToast();
	const updateProg = (prog) => setProg(prog);

	const getAndSetAllUnits = useCallback(async () => {
		await apiFetch(httpGetAllUnits());
	}, [apiFetch]);

	// ------------ EVENT HANDLERS ----------------------------------

	const handleRouteChange = useCallback(
		(newRoute) => {
			sessionStorage.setItem("route", JSON.stringify({ route: newRoute, page: page }));
			setRoute(newRoute);
		},
		[page],
	);

	const handlePageChange = useCallback(
		(newPage) => {
			sessionStorage.setItem("route", JSON.stringify({ route: route, page: newPage }));
			setPage(newPage);
		},
		[route],
	);

	const handleLogout = useCallback(async () => {
		let res = await httpLogOutUser();
		sessionStorage.removeItem("route");
		setAllUnits([]);
		setRoute(LOGIN);
		setPage(DASHBOARD);
		removeToast();
		resetOnceOnlyToast();
	}, [removeToast, resetOnceOnlyToast]);
	// ------------ EFFECTS ----------------------------------

	//if api succeed, set all units. if api error, show modal and delete tokens.
	useEffect(() => {
		if (route === LOGOUT || route === LOGIN) {
			setAllUnits([]);
			return;
		}
		if (!apiLoading && apiError) {
			deleteAuthTokenFromSessionStorage();
		} else if (!apiError && apiData) {
			setAllUnits(apiData);
		}
	}, [apiLoading, apiError, apiData, route]);

	useEffect(() => {
		// only leave LOGIN route if fetched all units and no units with no drones
		if (allUnits.length > 0 && route !== LOGOUT) {
			if (unitsWithNoDrones.length) {
				setRoute(REGISTER_DRONES);
			} else {
				let sessionRoute = JSON.parse(sessionStorage.getItem("route"));
				if (sessionRoute) {
					setRoute(sessionRoute.route);
					setPage(sessionRoute.page);
					return;
				} else {
					handleRouteChange(MAIN);
				}
			}
		}
	}, [allUnits, unitsWithNoDrones, route, handleRouteChange, resetOnceOnlyToast]);

	// check for existing session token
	const token = window.sessionStorage.getItem("token");
	useEffect(() => {
		if (token && !apiData && !apiLoading && route !== LOGOUT) {
			// fetch unit data if user has login token and has not already fetched unit data
			getAndSetAllUnits();
		}
		if (!token) {
			setRoute(LOGIN);
		}
	}, [apiData, apiLoading, getAndSetAllUnits, route, token]);

	//if logout route, reset
	useEffect(() => {
		route === LOGOUT && handleLogout();
	}, [route, handleLogout]);

	//when modal closed, reset fetch status and bring back to login
	const closeModal = () => {
		resetFetchStatus();
		setRoute(LOGIN);
	};

	// ------------ APP ----------------------------------
	const Fallback = () => {
		return (
			<div className='body body-scroll'>
				<CustomModal
					showModal={true}
					handleClose={() => {
						handleLogout();
						window.location.reload();
					}}
					clickAway={true}
				>
					<p>Something went wrong. Please sign in again.</p>
				</CustomModal>
			</div>
		);
	};

	return (
		<div className='App' id='App'>
			<ErrorBoundary FallbackComponent={Fallback}>
				<ThemeProvider>
					<UserProvider units={allUnits}>
						{route !== MAIN ? (
							<ErrorModal>
								<Login
									route={route}
									getAndSetAllUnits={getAndSetAllUnits}
									handleRouteChange={handleRouteChange}
									unitsWithNoDrones={unitsWithNoDrones}
									handleLogout={handleLogout}
								/>
							</ErrorModal>
						) : !!allUnits.length ? ( //dont start fetching data until allUnits has been set
							<>
								<Suspense fallback={<Splash prog={prog} updateProg={updateProg} />}>
									<WebsocketProvider>
										<ErrorModal>
											<Header />
											<AppLogic
												handleRouteChange={handleRouteChange}
												route={route}
												page={page}
												allUnits={allUnits}
												prog={prog}
											/>
											<SideBar
												page={page}
												handleRouteChange={handleRouteChange}
												handlePageChange={handlePageChange}
											/>
										</ErrorModal>
									</WebsocketProvider>
								</Suspense>
							</>
						) : (
							//dont show blank screen while waiting for allUnits to be set or on logout
							<Splash prog={prog} updateProg={updateProg} />
						)}
					</UserProvider>
				</ThemeProvider>
			</ErrorBoundary>

			<CustomModal showModal={apiError} handleClose={() => closeModal()} clickAway={true}>
				<p>{apiData}</p>
			</CustomModal>
			<ToastContainer
				containerId='containerId'
				position='bottom-right'
				autoClose={false}
				newestOnTop={false}
				closeOnClick
				pauseOnFocusLoss={false}
				rtl={false}
				draggable
				theme={"colored"}
				limit={3}
			/>
		</div>
	);
}

export default App;
