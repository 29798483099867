import { useCallback, useEffect, useState } from "react";

export const usePreferences = () => {
	const [preferences, setPreferences] = useState({
		notifications: true,
		mute: null,
		timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
		timezoneLocalOffset: 0,
	});

	useEffect(() => {
		let storedPreferences = window.localStorage.getItem("preferences");
		if (!storedPreferences) {
			window.localStorage.setItem("preferences", JSON.stringify(preferences));
		} else {
			setPreferences({ ...preferences, ...JSON.parse(storedPreferences) });
		}
	}, []); //intentionally empty array to run once

	const updatePreferences = useCallback((preferences) => {
		window.localStorage.setItem("preferences", JSON.stringify(preferences));
		setPreferences(preferences);
	}, []);

	return {
		preferences,
		updatePreferences,
	};
};
