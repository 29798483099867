import { Fragment, useEffect } from "react";
import { createPortal } from "react-dom";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useClickAwayListener } from "hooks/useClickAwayListener";
import "./CustomModal.css";

/**
 * CustomModal
 * @param {boolean} showModal - show/hide modal
 * @param {object} modalContent - content to display in modal
 * @param {string} modalContent.title - title to display in modal. default is "".
 * @param {string} modalContent.closeBtn - close button text. default is false
 * @param {string} modalContent.cancelBtn - cancel button text. default is false
 * @param {string} modalContent.submitBtn - submit button text. default is false
 *
 * @param {function} handleClose - function to close modal
 * @param {function} handleSubmit - function to submit modal
 * @param {string} styleClasses - additional style classes. default is ""
 * @param {boolean} clickAway - close modal on click away. default is true
 * @param {boolean} background - show/hide background. default is true
 * @param {object} children - children to display in modal
 */

export default function CustomModal({
	showModal,
	modalContent,
	handleClose,
	handleSubmit,
	styleClasses = "",
	clickAway = true,
	background = true,
	children,
	msgTraceSrc = "NA",
}) {
	const modalDisplayClassName = showModal ? "display-block" : "display-none";
	const modalBgClassName = background ? "modal-bg" : "";

	const title = modalContent?.title ?? "";
	const closeBtn = modalContent?.closeBtn ?? false;
	const cancelBtn = modalContent?.cancelBtn ?? false;
	const submitBtn = modalContent?.submitBtn ?? false;

	let clickAwayRef = useClickAwayListener(handleClose);

	//focus on modal close btn if exists so that keydown events close modal
	useEffect(() => {
		showModal && closeBtn && document.getElementById("modal-close").focus();
	}, [showModal, closeBtn]);

	return showModal
		? createPortal(
				<Fragment>
					<div className={`${modalDisplayClassName} ${modalBgClassName}`}>
						<section
							className={`modal-main ${styleClasses}`}
							id='modal-main'
							ref={clickAway ? clickAwayRef : null}
						>
							{title && <h3 id='modal-title'>{title}</h3>}
							<div id='modal-message' data-testid='modal-message' data-tracesrc={msgTraceSrc}>
								{children}
							</div>
							{closeBtn && (
								<button
									className='clickable'
									id='modal-close'
									data-testid='modal-close'
									onClick={handleClose}
								>
									<FontAwesomeIcon icon={faXmark} className='icon' onClick={handleClose} />
								</button>
							)}
							<div className='modal-input'>
								{cancelBtn && (
									<button
										className='clickable'
										id='modal-cancel'
										data-testid='modal-cancel'
										onClick={handleClose}
									>
										{`${cancelBtn}`}
										<FontAwesomeIcon icon={faXmark} className='icon' />
									</button>
								)}
								{submitBtn && (
									<button
										className='clickable'
										id='modal-submit'
										data-testid='modal-submit'
										onClick={handleSubmit}
									>
										{`${submitBtn}`}
										<FontAwesomeIcon icon={faCheck} className='icon submit' />
									</button>
								)}
							</div>
						</section>
					</div>
				</Fragment>,
				document.getElementById("App"),
			)
		: null;
}
