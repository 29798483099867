import { useCallback, useReducer } from "react";

export const useFetch = () => {
	const initialState = {
		apiLoading: false,
		apiData: null,
		apiError: false,
	};

	const reducer = (state, action) => {
		switch (action.type) {
			case "SUCCESS":
				return {
					apiLoading: false,
					apiData: action.payload,
					apiError: false,
				};

			case "ERROR":
				return {
					apiLoading: false,
					apiData: action.payload,
					apiError: true,
				};
			case "LOADING":
				return {
					apiLoading: true,
					apiData: null,
					apiError: false,
				};
			case "RESET":
				return initialState;

			default:
				return state;
		}
	};
	const [fetchStatus, setFetchStatus] = useReducer(reducer, initialState);

	const apiFetch = useCallback(async (reqFunction) => {
		setFetchStatus({ type: "LOADING" });
		const data = await reqFunction;
		if (data.error) {
			setFetchStatus({ type: "ERROR", payload: data.error });
			return false;
		} else {
			setFetchStatus({ type: "SUCCESS", payload: data });
			return data;
		}
	}, []);

	const resetFetchStatus = useCallback(() => {
		setFetchStatus({ type: "RESET" });
	}, []);

	return { ...fetchStatus, apiFetch, resetFetchStatus };
};
