import "./SidebarPopup.css";

export default function SidebarPopup({ popupOpen, popupListItems }) {
	return (
		<div className='sidebar-modal' style={{ visibility: popupOpen ? "visible" : "hidden" }}>
			<ul>
				{popupListItems.map((item, index) => {
					return (
						<li
							key={index}
							onClick={() => {
								item.onClick();
							}}
						>
							{item.name}
						</li>
					);
				})}
			</ul>
		</div>
	);
}
