import { useEffect, useRef } from "react";

export const useClickAwayListener = (handleClickAway) => {
	const ref = useRef(null);

	useEffect(() => {
		function handleClickOutside(event) {
			if (ref.current && !ref.current.contains(event.target)) {
				handleClickAway();
			}
		}

		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref, handleClickAway]);
	return ref;
};

// USAGE

//  let clickAwayRef = useClickAwayListener(handleclickaway);
// <Component ref={clickawayref}>
