import { memo, useEffect, useRef } from "react";
import nactLogoAnim from "assets/nact-logo-animated-SD-compress.mp4";
import BgCircuit from "components/_GENERAL/BgCircuit";
import "./Splash.css";

function Splash({ prog, updateProg = null }) {
	const videoRef = useRef(null);
	useEffect(() => {
		if (prog && videoRef.current) {
			videoRef.current.currentTime = prog;
		}
		//intentional empty dep array to run only once
	}, []);

	return (
		<div className='loading fc'>
			<video
				ref={videoRef}
				autoPlay
				loop
				muted
				preload='true'
				id='logoanim'
				onTimeUpdate={(event) => {
					updateProg && updateProg(event.target.currentTime);
				}}
			>
				<source src={nactLogoAnim} type='video/mp4' />
			</video>
			<BgCircuit opacity={0.6} animDuration={"20s"} bgColor={"transparent"} />
		</div>
	);
}

export default memo(Splash);
