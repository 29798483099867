import { Children, cloneElement, useCallback, useState } from "react";
import CustomModal from "./CustomModal";

export default function ErrorModal({ children }) {
	const [errMessage, setErrMessage] = useState("");
	const [errCloseHandler, setErrCloseHandler] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [hasClickaway, setHasClickaway] = useState(false);

	/**
	 * handleErr takes a message string and a close handler function.
	 * If handler is provided, the modal will have a clickaway and the handler will be called when the modal is closed else no clickaway.
	 * @param {string} message
	 * @param {function} handler
	 * @returns {void} opens error modal with message
	 */
	const handleErr = useCallback((message, handler) => {
		setShowModal(true);
		setErrMessage(message);
		setErrCloseHandler({
			// cannot directly store function in state, so store as object value and call it later
			close: () => {
				handler && handler();
				setShowModal(false);
				setErrMessage("");
				setErrCloseHandler(null);
			},
		});
		setHasClickaway(handler ? true : false);
	}, []);
	// const traverseAndCloneChildren = useCallback(
	// 	(children) => {
	// 		return Children.map(children, (child) => {
	// 			if (isValidElement(child)) {
	// 				const childProps = { handleErr };

	// 				if (child.props.children) {
	// 					childProps.children = traverseAndCloneChildren(child.props.children);
	// 				}

	// 				return cloneElement(child, childProps);
	// 			}

	// 			return child;
	// 		});
	// 	},
	// 	[handleErr]
	// );
	return (
		<>
			{Children.map(children, (child) => cloneElement(child, { handleErr }))}
			<CustomModal
				showModal={showModal}
				modalContent={{
					closeBtn: hasClickaway,
				}}
				clickAway={hasClickaway}
				handleClose={() => {
					errCloseHandler.close();
				}}
			>
				{errMessage}
			</CustomModal>
		</>
	);
}
