import { useEffect, useMemo, useState } from "react";
import { faSpinner, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdown from "components/_GENERAL/Dropdown";
import DroneList from "components/pagesDRONEREG/DroneList";
import { httpSaveNewDrones } from "requests/requests";
import "./DroneRegistration.css";

const dronePreset = [
	{ model: "DJI Phantom 4 Series", weight: 1.4 },
	{ model: "DJI Mavic 3 Series", weight: 0.9 },
	{ model: "DJI Mavic 2 Series", weight: 0.9 },
	{ model: "DJI Matrice 200 Series", weight: 4.9 },
	{ model: "DJI Matrice 300 RTK", weight: 9 },
	{ model: "DJI Matrice 600", weight: 9.6 },
	{ model: "DJI Matrice 30", weight: 3.8 },
	{ model: "Parrot Anafi", weight: 0.5 },
];
const dronePresetModels = dronePreset.map((drone) => drone.model);

export default function DroneRegistration({ handleRegComplete, units, handleErr, handleLogout }) {
	let unitIDs = useMemo(() => units.map((unit) => unit.unitID), [units]);

	const [unitID, setUnitID] = useState("");
	const [selectedDrones, setSelectedDrones] = useState([]);

	const [errorMessage, setErrorMessage] = useState("");
	const [isPending, setIsPending] = useState(false);
	let dronesByUnit = selectedDrones.filter((drone) => drone.unitID === unitID);

	//set default to first unit.
	useEffect(() => {
		setUnitID(unitIDs[0]);
		let initDrones = unitIDs.map((unitID) => {
			return { unitID: unitID, id: 0, model: "", weight: 0 };
		});
		setSelectedDrones(initDrones);
	}, [unitIDs]);

	//handle error modal
	useEffect(() => {
		errorMessage && handleErr(errorMessage, () => setErrorMessage(""));
	}, [errorMessage, handleErr]);

	//remove any blank listing
	let formData = selectedDrones.filter((drone) => {
		return drone.model === "" && drone.weight === 0 ? false : true;
	});
	//check if any half-filled
	let isHalfFilled = formData.filter((drone) => {
		return drone.model === "" || drone.weight === 0 ? true : false;
	});
	//check if any units has no drones
	let hasNoDrones = unitIDs.filter((unitID) =>
		formData.filter((drone) => drone.unitID === unitID).length === 0 ? true : false,
	);

	const handleDroneSubmit = async (e) => {
		e.preventDefault();
		setIsPending(true);

		if (hasNoDrones.length) {
			setUnitID(hasNoDrones[0]);
			setIsPending(false);
			return;
		}

		if (isHalfFilled.length) {
			let errMessage = (
				<>
					<p>{`Please fill in all fields with both model and weight.`}</p>
					<ul>
						{isHalfFilled.map((drone) => (
							<li>{`• ${drone.unitID}`}</li>
						))}
					</ul>
					<br />
					<br />
				</>
			);
			setErrorMessage(errMessage);
			setIsPending(false);
			setSelectedDrones(formData);
			return;
		}

		let cleanedData = formData.reduce(
			(acc, drone, i) => {
				let droneUnit = drone.unitID;
				let numCables = units.find((item) => item.unitID === droneUnit).specs.cables.length;
				let cablesAlreadyAssigned = units
					.find((unit) => unit.unitID === unitID)
					.drones.filter((drone) => drone.cableID).length;
				let cablesJustAssigned = acc[0];
				let cablesAvailable = numCables - cablesAlreadyAssigned - cablesJustAssigned;
				let droneData = {
					model: drone.model,
					weight: drone.weight,
					cableID: cablesAvailable ? numCables - cablesAvailable + 1 : null,
				};
				if (cablesAvailable) {
					acc[0] += 1;
				}

				let unitIndex = acc.findIndex((unit) => unit.unitID === droneUnit);
				if (unitIndex === -1) {
					acc.push({ unitID: droneUnit, drones: [droneData] });
				} else {
					acc[unitIndex].drones.push(droneData);
				}
				if (i === formData.length - 1) {
					acc.shift();
				}
				return acc;
			},
			[0],
		);
		let response = await httpSaveNewDrones(cleanedData);

		if (response.error) {
			setErrorMessage(<p>{response.error}</p>);
			setIsPending(false);
			return;
		}

		handleRegComplete(cleanedData);
	};

	const handleUnitSelection = (unit) => {
		setUnitID(unit);
	};

	const handleInputByIndex = (index) => (change) => {
		let updatedDrone = selectedDrones.filter((drone) => {
			return drone.id === index && drone.unitID === unitID ? true : false;
		})[0];
		let unchangedDrones = selectedDrones.filter((drone) => {
			return drone.unitID !== unitID || drone.id !== index ? true : false;
		});

		if ("model" in change) {
			updatedDrone.model = change.model;
			let isPreset = dronePreset[dronePresetModels.indexOf(change.model)];
			if (isPreset) {
				updatedDrone.weight = isPreset.weight;
			}

			return setSelectedDrones([...unchangedDrones, updatedDrone]);
		}
		if ("weight" in change) {
			updatedDrone.weight = change.weight;
			return setSelectedDrones([...unchangedDrones, updatedDrone]);
		}
	};

	const handleAddDrone = (newDrone) => {
		setSelectedDrones([...selectedDrones, { unitID: unitID, ...newDrone }]);
	};

	const handleRemoveDrone = (index) => {
		let remainingDrones = selectedDrones.filter((drone) => drone.id !== index);
		setSelectedDrones(remainingDrones);
	};

	const handleEnterKey = (e) => {
		if (e.key === "Enter") {
			e.preventDefault();
		}
	};

	return (
		<div className='sub-section login drone-reg'>
			<form className='fc' onSubmit={handleDroneSubmit} onKeyDown={handleEnterKey}>
				<h1>Drone Registration</h1>
				{handleLogout && (
					<button
						id='dronereg-back-to-signin-btn'
						title='Back to Sign In'
						className='clickable'
						onClick={() => handleLogout()}
					>
						<FontAwesomeIcon icon={faArrowLeft} className='icon' />
					</button>
				)}

				{unitIDs && !!dronesByUnit.length && (
					<div style={{ margin: "1.5em 0", width: "100%" }}>
						<Dropdown
							id={"unitID-reg-dropdown"}
							contentList={unitIDs}
							defaultOption={unitID}
							extSelectionHandler={handleUnitSelection}
						/>

						<DroneList
							unitDrones={dronesByUnit}
							handleInputByIndex={handleInputByIndex}
							dronePresetModels={dronePresetModels}
							handleAddDrone={handleAddDrone}
							handleRemoveDrone={handleRemoveDrone}
						/>
					</div>
				)}

				<button className='submit' type='submit' data-testid='drone-reg-submit-btn'>
					{isPending && (
						<FontAwesomeIcon
							icon={faSpinner}
							className='icon'
							style={{
								animation: "rotateR 1s ease-out infinite",
								color: "white",
								marginRight: "0.5em",
							}}
						/>
					)}
					{hasNoDrones.length ? "Next" : "Submit"}
				</button>
				<p id='drone-reg-info'>*Min. one drone must be registered per unit.</p>
			</form>
		</div>
	);
}
