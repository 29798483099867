import { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import { CONNECTED, RECEIVING } from "constants/constants";

export const WebsocketContext = createContext(false, null, () => {});
//                                            ready, ws, send

export const WebsocketProvider = ({ children }) => {
	const [wsStatus, setWsStatus] = useState(""); //CONNECTED, RECEIVING
	const [wsMessage, setWsMessage] = useState(null);
	// message = {
	// 	type:""  //cameraStatus or rtFlightData or droneCommand or droneSocketRes
	//	data:{}
	// }

	const ws = useRef(null);

	const openWs = useCallback(() => {
		const socket = new WebSocket(
			process.env.REACT_APP_EXPRESS_WS
				? `${process.env.REACT_APP_EXPRESS_WS}/ws`
				: `ws://localhost:${process.env.REACT_APP_EXPRESS_API_PORT}/ws`,
		);
		socket.onopen = (event) => {
			console.log("ws opened");
			setWsStatus(CONNECTED);
		};

		socket.onclose = (event) => {
			console.log("ws closing");
			setWsStatus("");
			setWsMessage(null);
		};
		socket.onerror = () => {
			console.log("ws error");
			setWsStatus("");
		};

		return socket;
	}, []);

	//OPEN WEBSOCKET
	useEffect(() => {
		const socket = openWs();
		ws.current = socket;

		return () => {
			socket.close();
			ws.current = null;
		};
	}, [openWs]);

	// //WEBSOCKET RECEIVE DATA
	useEffect(() => {
		const wsMessageHandler = (event) => {
			let message = JSON.parse(event.data);

			if (wsStatus !== RECEIVING) {
				setWsStatus(RECEIVING);
			}

			setWsMessage(message);

			if (message?.data?.close) {
				//no long receiving rtdata
				setWsStatus(CONNECTED);
				return;
			}
		};
		ws.current.addEventListener("message", wsMessageHandler);
		return () => {
			ws.current && ws.current.removeEventListener("message", wsMessageHandler);
		};
	}, [wsStatus, wsMessage]);

	const sendWsMsg = useCallback((msg) => {
		ws.current?.send(msg);
	}, []);

	const closeWs = useCallback(() => {
		ws.current?.close();
		ws.current = null;
	}, []);

	const retryWsConn = useCallback(() => {
		if (ws.current) {
			closeWs();
		}
		const socket = openWs();
		ws.current = socket;
	}, [openWs, closeWs]);

	return (
		<WebsocketContext.Provider value={{ wsStatus, sendWsMsg, wsMessage, closeWs, retryWsConn }}>
			{children}
		</WebsocketContext.Provider>
	);
};

export const useWebSocket = () => {
	const { wsStatus, sendWsMsg, wsMessage, closeWs, retryWsConn } = useContext(WebsocketContext);
	return { wsStatus, sendWsMsg, wsMessage, closeWs, retryWsConn };
};

export const useWsCameraStatus = () => {
	const { wsMessage } = useContext(WebsocketContext);
	const lastRelevantMessageRef = useRef(null);

	// set lastRelevantMessageRef to the last message that is a cameraStatus
	useEffect(() => {
		if (wsMessage?.type === "cameraStatus") {
			lastRelevantMessageRef.current = { cameraStatus: wsMessage.data };
		}
	}, [wsMessage]);

	if (wsMessage?.type === "cameraStatus") {
		return { cameraStatus: wsMessage.data };
	} else {
		return lastRelevantMessageRef.current ? lastRelevantMessageRef.current : { cameraStatus: null };
	}
};

export const useWsRtFlightData = () => {
	const { wsMessage } = useContext(WebsocketContext);
	const lastRelevantMessageRef = useRef(null);

	// set lastRelevantMessageRef to the last message that is a rtFlightData
	useEffect(() => {
		if (wsMessage?.type === "rtFlightData") {
			lastRelevantMessageRef.current = { rtFlightData: wsMessage.data };
		}
	}, [wsMessage]);

	if (wsMessage?.type === "rtFlightData") {
		return { rtFlightData: wsMessage.data };
	} else {
		return lastRelevantMessageRef.current ? lastRelevantMessageRef.current : { rtFlightData: null };
	}
};

export const useWsDroneSocketRes = () => {
	const { wsMessage } = useContext(WebsocketContext);
	const lastRelevantMessageRef = useRef(null);

	// set lastRelevantMessageRef to the last message that is a droneSocketRes
	useEffect(() => {
		if (wsMessage?.type === "droneSocketRes") {
			let droneSocketStatus = wsMessage.data.message !== "socketIsClosed" ? "OPEN" : "CLOSED";
			lastRelevantMessageRef.current = { droneSocketRes: wsMessage, droneSocketStatus: droneSocketStatus };
		}
	}, [wsMessage]);

	if (wsMessage?.type === "droneSocketRes") {
		return {
			droneSocketRes: wsMessage,
			droneSocketStatus: wsMessage.data.message !== "socketIsClosed" ? "OPEN" : "CLOSED",
		};
	} else {
		return lastRelevantMessageRef.current
			? lastRelevantMessageRef.current
			: { droneSocketRes: null, droneSocketStatus: null };
	}
};

export const useWsClientReq = () => {
	const { wsMessage } = useContext(WebsocketContext);
	const [clientRequest, setClientRequest] = useState(null);
	const lastRelevantMessageRef = useRef(null);

	const resetClientReq = useCallback(() => {
		lastRelevantMessageRef.current = { clientRequest: null, resetClientReq };
		setClientRequest(null);
	}, []);

	useEffect(() => {
		if (wsMessage?.type === "clientRequest") {
			setClientRequest(wsMessage);
			lastRelevantMessageRef.current = { clientRequest: wsMessage, resetClientReq };
		}
	}, [resetClientReq, wsMessage]);

	if (wsMessage?.type === "clientRequest") {
		return { clientRequest: clientRequest, resetClientReq };
	} else {
		return lastRelevantMessageRef.current
			? lastRelevantMessageRef.current
			: { clientRequest: null, resetClientReq };
	}
};

export const useStandaloneWs = (wsXUrl) => {
	const [wsXStatus, setWsXStatus] = useState(""); //CONNECTED, RECEIVING
	const [wsXMessage, setWsXMessage] = useState(null);
	// message = {
	// 	type:""  //cameraStatus or rtFlightData or droneCommand or droneSocketRes
	//	data:{}
	// }

	const wsX = useRef(null);

	const openWsX = useCallback(() => {
		const socket = new WebSocket(
			process.env.REACT_APP_EXPRESS_WS
				? `${process.env.REACT_APP_EXPRESS_WS}/ws`
				: `ws://localhost:${process.env.REACT_APP_EXPRESS_API_PORT}/ws`,
		);
		socket.onopen = (event) => {
			console.log("wsX opened");
			setWsXStatus(CONNECTED);
		};

		socket.onclose = (event) => {
			console.log("wsX closing");
			setWsXStatus("");
			setWsXMessage(null);
		};
		socket.onerror = () => {
			console.log("wsX error");
			setWsXStatus("");
		};

		return socket;
	}, []);

	//OPEN WEBSOCKET
	useEffect(() => {
		const socket = openWsX();
		wsX.current = socket;

		return () => {
			socket.close();
			wsX.current = null;
		};
	}, [openWsX]);

	// //WEBSOCKET RECEIVE DATA
	useEffect(() => {
		const wsXMessageHandler = (event) => {
			let message = JSON.parse(event.data);

			if (wsXStatus !== RECEIVING) {
				setWsXStatus(RECEIVING);
			}

			setWsXMessage(message);

			if (message?.data?.close) {
				//no long receiving rtdata
				setWsXStatus(CONNECTED);
				return;
			}
		};
		wsX.current.addEventListener("message", wsXMessageHandler);
		return () => {
			wsX.current && wsX.current.removeEventListener("message", wsXMessageHandler);
		};
	}, [wsXStatus, wsXMessage]);

	const sendWsXMsg = useCallback((msg) => {
		wsX.current?.send(msg);
	}, []);

	const closeWsX = useCallback(() => {
		wsX.current?.close();
		wsX.current = null;
	}, []);

	const retryWsXConn = useCallback(() => {
		if (wsX.current) {
			closeWsX();
		}
		const socket = openWsX();
		wsX.current = socket;
	}, [openWsX, closeWsX]);

	return { wsXStatus, sendWsXMsg, wsXMessage, closeWsX, retryWsXConn };
};
